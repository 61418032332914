import React from 'react';
import API from '../../utils/API';
import { validation, validateField } from '../../utils/validation';
import defaultBrands from '../../utils/defaultBrands';
import './GearForm.css';
import FilteredInput from '../FilteredInput';
import Dropdown from '../Dropdown';
import ToolTip from '../ToolTip';


class GearForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            brandsList: defaultBrands,
            filteredBrands: defaultBrands,
            modelsList: [],
            filteredModels: [],
            brand: '',
            model: '',
            condition: '',
            color: '',
            year: '',
            serialNumber: '',
            askingMinPrice: '',
            askingMaxPrice: '',
            description: '',  
            currentDropdown: ''          
        };
    }

    componentDidMount() {
        const { currentItem } = this.props;

        this.setState({
            brandsList: this.props.brandsList, 
            filteredBrands: this.props.brandsList,
            brand: currentItem.brand ? currentItem.brand : '',
            model: currentItem.model ? currentItem.model : '',
            condition: currentItem.condition ? currentItem.condition : '',
            color: currentItem.color ? currentItem.color : '',
            year: currentItem.year ? currentItem.year : '',
            serialNumber: currentItem.serialNumber ? currentItem.serialNumber : '',
            askingMinPrice: currentItem.askingMinPrice ? currentItem.askingMinPrice : '',
            askingMinPrice: currentItem.askingMaxPrice ? currentItem.askingMaxPrice : '',
            description: currentItem.description ? currentItem.description : ''
        });
    }

    componentWillReceiveProps(props) {
       if (props.currentItem && props.currentItem.lineItemId > -1) {
           this.setState({
            description: props.currentItem.description ? props.currentItem.description : ''
           })
       }
    }
    

    getBrands = () => {
        const { filteredBrands } = this.state;
        if (filteredBrands && filteredBrands.length > 0) {
            return  filteredBrands.map((brand, i) => {
                return (
                    <button key={`brand-${i}`} onClick={(e)=>this.selectValue('brand', brand.Brand, e.type)} value={brand.brand}>{brand.Brand}</button>
                    );
            })
        }
            else {
                return <div>No results</div>
            }
    }

    getModels = () => {
        const { filteredModels } = this.state;
        if (filteredModels && filteredModels.length > 0) {
            return  filteredModels.map((model, i) => {
                return (
                <button key={`model-${i}`} onClick={()=>this.selectValue('model', model)}>{model}</button>
                );
            })
        } else {
            return <div>No results</div>
        }

    }

    getConditions = () => {
        const conditionsArray = ['Excellent', 'Great', 'Good', 'Fair'];

        return conditionsArray.map((condition, i) => {
            return (
            <button key={`cond-${i}`} onClick={()=>this.selectValue('condition', condition)}>{condition}</button>
            );
        })                                   
    }

    selectValue = (field, value, type) => {
        this.setState({[field]: value});
        const { updateItem } = this.props;
        if (field === 'brand' && type && type === 'click') {
            this.getModelsAPI(value);
        }
        updateItem(field, value);
    }

    getModelsAPI = (val) => {
        this.setState({modelsList: [], filteredModels: [], model: ''});
        const value = val ? val : this.state.brand; 

            API.getModels(value).then((res) => {
                if (res) {
                    this.setState({modelsList: res, filteredModels: res});
                } else {
                    this.setState({modelsList: [], filteredModels: [], model: ''});
                }
            }) ;
    }

    toggleError(e) {
        const name = e.target.name;
        const val = e.target.value;

        if (val && val.length > 2) {
            validateField(name);
        }
    }

    filterBrands = (e) => {
        const brandList = this.state.brandsList;
        const searchString = e.target.value;
        this.setState({brand: searchString});

        const updatedFilter = brandList.filter(brand => brand.Brand.toLowerCase().includes(searchString.toLowerCase()));

        this.setState({filteredBrands: updatedFilter});
    }

    filterModels = (e) => {
        const modelsList = this.state.modelsList;
        const searchString = e.target.value;
        this.setState({model: searchString});

        const updatedFilter = modelsList.filter(model => model.toLowerCase().includes(searchString.toLowerCase()));

        this.setState({filteredModels: updatedFilter});
    }

    toggleAllDropdowns = (name) => {
        this.setState({currentDropdown: name});
    }

    render() {
        const imagesLength = this.props.currentItem.images ? this.props.currentItem.images.length : 0;

        return ( 
            <div id="gear-info-form" className="gear-information row">
                {/* <div className="col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="brand-field">
                        <span>Brand</span>
                        <ToolTip className="tooltip-icon" showToolTip={this.state.showToolTip}
                        btnText="?"
                        headerText="Brand"
                        toolTipId="brand-input" />
                    </label>
                    <FilteredInput name="brand" getModelsAPI={this.getModelsAPI} toggleAllDropdowns={this.toggleAllDropdowns} currentDropdown={this.state.currentDropdown} onKeyUp={(e)=>this.toggleError(e)} 
                    // validate={validation.isValidString(this.state.brand) ? "true" : "false"} 
                    getList={this.getBrands} filterList={this.filterBrands} selectValue={this.selectValue} value={this.state.brand} placeholder="Type to search"/>
                    <span data-js-error="brand" className="error-msg">Please enter a valid brand</span>
                    <label htmlFor="model-field">
                        <span>Model</span>
                        <ToolTip className="tooltip-icon" showToolTip={this.state.showToolTip}
                        btnText="?"
                        headerText="Model"
                        toolTipId="model-input"/>
                    </label>
                    <FilteredInput name="model" getModelsAPI={this.getModelsAPI} placeholder="Search or type in" toggleAllDropdowns={this.toggleAllDropdowns} currentDropdown={this.state.currentDropdown} onKeyUp={(e)=>this.toggleError(e)} selectValue={this.selectValue}  getList={this.getModels} filterList={this.filterModels} value={this.state.model}/>
                    <span data-js-error="model" className="error-msg">Please enter a valid model</span>
                    <label htmlFor="condition-field">
                        <span>Condition</span>
                        <ToolTip className="tooltip-icon" showToolTip={this.state.showToolTip}
                        btnText="?"
                        headerText="Condition"
                        toolTipId="condition-input" />
                    </label>
                    <Dropdown name="condition" getList={this.getConditions} value={this.state.condition} toggleAllDropdowns={this.toggleAllDropdowns} currentDropdown={this.state.currentDropdown}/>
                    <span data-js-error="condition" className="error-msg">Please enter a valid condition</span>
                    <label htmlFor="color-field" >
                        <span>Color</span>
                        <ToolTip className="tooltip-icon" showToolTip={this.state.showToolTip}
                        btnText="?"
                        headerText="Color"
                        toolTipId="color-input" />
                    </label>
                    <input name="color" type="text" autoComplete="new-password" value={this.state.color} onChange={(e)=>this.selectValue('color', e.target.value)}></input>
                    <span data-js-error="color" className="error-msg">Please enter a valid color</span>
                
                    <label htmlFor="year-field">
                        <span>Year</span>
                        <ToolTip className="tooltip-icon" showToolTip={this.state.showToolTip}
                        btnText="?"
                        headerText="Year"
                        toolTipId="year-input" />
                    </label>
                    <input name="year" valid={validation.isValidYear(this.state.year) ? "true" : "false"} type="text" autoComplete="new-password" value={this.state.year} onChange={(e)=>this.selectValue('year', e.target.value)} onKeyUp={(e)=>this.toggleError(e)}></input>
                    <span data-js-error="year" className="error-msg">Please enter a valid year</span>
                    </div> */}
                    {/* <label htmlFor="serial-field">
                        <span>Serial Number</span>
                        <ToolTip className="tooltip-icon" showToolTip={this.state.showToolTip}
                        btnText="?"
                        headerText="Serial Number"
                        toolTipId="serial-input" /></label>
                    <input type="text" name="serialNumber" autoComplete="new-password" value={this.state.serialNumber} onChange={(e)=>this.selectValue('serialNumber', e.target.value)}></input> */}
                    {/* <span data-js-error="serialNumber" className="error-msg">Tell Us More About Your Gear</span> */}
                    <label htmlFor="description-field">
                        <span>Tell Us More About Your Gear</span>
                        <ToolTip className="tooltip-icon" showToolTip={this.state.showToolTip}
                        btnText="?"
                        headerText="Tell Us More About Your Gear"
                        toolTipId="description-input" />
                    </label>
                    <textarea type="text" placeholder="Please mention blemishes, cracks, broken items or included products here." name="description" autoComplete="new-password" value={this.state.description} onChange={(e)=>this.selectValue('description', e.target.value)}></textarea>
                    <span data-js-error="description" className="error-msg">Please enter a valid description</span>
                    {/* <label htmlFor="imageCount" style={{display:"none"}}>
                        <span>Number of images</span>
                    </label>
                    <input type="hidden" className="hidden" name="imageCount" value={imagesLength} valid={validation.isValidImageLength(imagesLength) ? "true" : "false"}/> */}
                </div>
        );
    }
}

export default GearForm;