import React from 'react';
import ImageUploader from '../../components/Uppy';
import NextButton from '../../components/NextButton';
import BackButton from '../../components/BackButton';
import ToolTip from '../../components/ToolTip';
import './SubmitDetails.css';
import GearForm from '../../components/GearForm/GearForm';
import PriceRange from '../../components/PriceRange/PriceRange';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
class SubmitDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            askingMinPrice: 0,
            askingMaxPrice: 0
        };

    }

    componentDidMount() {
        if (this.props.currentItem) {
            this.setState({askingMinPrice: this.props.currentItem.askingMinPrice, askingMaxPrice: this.props.currentItem.askingMaxPrice});
            this.props.updateItem('askingMinPrice', this.props.currentItem.askingMinPrice);
            this.props.updateItem('askingMaxPrice', this.props.currentItem.askingMaxPrice);
        } else {
            this.props.updateItem('askingMinPrice', 0);
            this.props.updateItem('askingMaxPrice', 0);
        }

    }

    formSubmitHandler = (e) => {
        e.preventDefault(); //should prevent submit, and continue below?
        return false;
    }
    
    updateValue = (e) => {
        // const name = e.target.name;
        // const val = e.target.value;
        // this.setState({
        //     [name]: val
        // });
        this.props.updateItem(e.target.name, e.target.value);

    }
    render() {
        const { updateImagesArray, brandsList, currentItem } = this.props;
        return ( 
            // <TransitionGroup>
            //     <CSSTransition
            //         className="example"
            //         timeout={{ enter: 5000, exit: 5000 }}>
        <div className="details-container">  
            <div className="details-form row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <form onSubmit={this.formSubmitHandler}>
                        <GearForm brandsList={brandsList} validateForm={this.validateForm} updateItem={this.props.updateItem} currentItem={currentItem}/>
                    </form>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <label htmlFor="image-uploader">Photos of your gear </label>
                    <div name="image-uploader" className="image-uploader">
                        <ImageUploader updateImagesArray={updateImagesArray} updateItem={this.props.updateItem} currentItem={currentItem} />
                        <div className="image-uploader-preview"> 
                        </div>
                    </div>
               
                </div>
            </div>     
            <div className="button-container">
                <NextButton text="Save" updateStep={this.props.updateStep} submitItem={true} closeModal={this.props.hideDetails} stepNum={4}/>
                <button className="btn btn-primary back-btn" onClick={this.props.hideDetails}>Cancel</button>

            </div>
        </div>
        // </CSSTransition>
        // </TransitionGroup>
        );
    }
}

export default SubmitDetails;