import React from 'react';
import getFAQ from '../utils/getFAQ';
class FAQ extends React.Component {
    
    constructor(props) {
      super(props);

      this.state = {
        showText: false 
      };
  }
    // toggleText = () => {
    //     const { showText } = this.state;
    //     if (showText) {
    //         this.setState({showText: false });
    //     } else {
    //         this.setState({showText: true });
    //     }
    // }
 
    render() {
      const { header, FAQId, id } = this.props;
      return (
            <div id={id ? id : ''} className="col-md-4 col-sm-12">
                <a className="FAQ-container_heading">{header}</a>
                <p className="FAQ-container_text">{getFAQ(FAQId)}</p>
            </div>
        );
    }
 
  }
  export default FAQ;