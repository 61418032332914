
const validation = {
    isValidString: function(string, length) {
        if (length) {
            if (string && string.length >= length) {
                return true;
            } else {
                return false;
            }
        } else {
            if (string && string.length) {
                
                return true;
            }
        }
    },
    isValidEmail: function(string) {
        const validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (validEmail.test(string)) {
            return true;
        } else {
            return false;
        }
    },
    isValidYear: function(string) {
        const validYear = /^(?:19|20)\d{2}$/;
        if (string) {
            if (validYear.test(string)) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }

    }, 
    isValidImageLength: function(string) {
        const valueInt = parseInt(string, 10);
        if (valueInt >= 2) {
            return true;
        } else {
            return false;
        }
    },
    isValidPrice: function(string) {
        const price = parseInt(string);
        if (price >= 1) {
            return true;
        } else {
            return false;
        }
    },
    isValidPhone: function(string) { 
        if (string && string.length > 0) {
            const validPhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            if (validPhone.test(string)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }

    }
};

function validateForm(num) {
    const parentId = num === 4 ? 'gear-info-form' : 'your-info-form';
    const parentEl = document.getElementById(parentId);
    const inputElements = parentEl.querySelectorAll("input");

    return new Promise(resolve => {
        let isValid = true;
        
        inputElements.forEach(function(element, i) {
        const name = element ? element.getAttribute('name') : null;
        const errorEl = document.querySelector(`[data-js-error=${name}]`);
        if (element && element.getAttribute('valid') === 'false') {   
            if (errorEl) {
                errorEl.setAttribute('data-show', 'true');
            }
            isValid = false;
        } else {
            if (errorEl) {
                errorEl.setAttribute('data-show', 'false');
            }
        }
        if (i === inputElements.length-1) {
            resolve(isValid);
        }
    });


})
}

function validateField(name) {
    const element = document.querySelector(`[name=${name}]`);
    const isValid = element && element.getAttribute('valid') ? element.getAttribute('valid') : null;
    if (!isValid) {
        return;
    }

    const errorEl = document.querySelector(`[data-js-error=${name}]`);
    if (element && element.getAttribute('valid') === 'true') {   
        if (errorEl) {
            errorEl.setAttribute('data-show', 'false');
        }
    }
}

export { validation, validateForm, validateField };