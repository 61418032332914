import moment from 'moment';

// formatting store details for display in store selector and confirmation page
function formatStoreInfo(selectedStore) {    
    if (selectedStore && selectedStore.id) {
        let phoneNum = selectedStore.phone;
        const cleaned = ('' + phoneNum).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        phoneNum = '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
        return (
            <div>
                <p>{selectedStore.address}</p>
                <p>{selectedStore.city + ', ' + selectedStore.state + ' ' + selectedStore.zip}</p>
                <p>{phoneNum}</p>
            </div>
        )
    } else {
        return;
    }
}

// formatting store hours for display on store selector and confirmation page
function formatStoreHours(selectedStore) {
    if (selectedStore && selectedStore.id) {
        const hoursString = selectedStore.hours;
        let hoursArray = hoursString ? hoursString.split(','): [];

        hoursArray = hoursArray.map((item, i) => {
            const day = item.slice(2);
            let open = day.slice(0,5);
            open = moment(open, "HH:mm").format("h:mm A");
            let close = day.slice(6);
            close = moment(close, "HH:mm").format("h:mm A");

            const dayObj = {
                open: open, close: close
            };
            return dayObj;
        });

        const sundayHours = `${hoursArray[0].open} - ${hoursArray[0].close}`;
        // todo: check that all weekday hours are the same, if, return in a different format
        const weekdayHours = `${hoursArray[1].open} - ${hoursArray[1].close}`;
        const saturdayHours = `${hoursArray[6].open} - ${hoursArray[6].close}`;

        return (
            <div>
                <p className="store-info-bold">Store Hours</p>
                <p>SUN: {sundayHours ? sundayHours : ''}</p>
                <p>MON-FRI: {weekdayHours ? weekdayHours : ''}</p>
                <p>SAT: {saturdayHours ? saturdayHours : ''}</p>
            </div>
        )
    } else {
        return;
    }
}

// formatting the store hours to save in state to send in final POST request 
function saveStoreHours(selectedStore) {
    if (selectedStore && selectedStore.id) {
        const hoursString = selectedStore.hours;
        let hoursArray = hoursString ? hoursString.split(','): [];

        hoursArray = hoursArray.map((item, i) => {
            const day = item.slice(2);
            let open = day.slice(0,5);
            open = moment(open, "HH:mm").format("h:mm A");
            let close = day.slice(6);
            close = moment(close, "HH:mm").format("h:mm A");

            const dayObj = {
                open: open, close: close
            };
            return dayObj;
        });

        const sun = `SUN: ${hoursArray[0].open} - ${hoursArray[0].close}`;
        const week = `MON-FRI: ${hoursArray[1].open} - ${hoursArray[1].close}`;
        const sat = `SAT: ${hoursArray[6].open} - ${hoursArray[6].close}`;
        const preferredStoreHours = [];
        preferredStoreHours.push(sun, week, sat);
        return preferredStoreHours;
    }
}

function formatHoursForScheduler(selectedStore) {
    let hoursArray = [];
    if (selectedStore && selectedStore.id) {
        const hoursString = selectedStore.hours;
        hoursArray = hoursString ? hoursString.split(','): [];

        hoursArray = hoursArray.map((item, i) => {
            const day = item.slice(2);
            let open = day.slice(0,5);
            open = parseInt(open.slice(0,2))
            let close = day.slice(6);
            close = parseInt(close.slice(0,2))

            const dayObj = { 
                open: open, close: close
            };
            return dayObj;
        });
        return hoursArray;
    }
}

export { formatStoreInfo, formatStoreHours, saveStoreHours, formatHoursForScheduler };