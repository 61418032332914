import React from 'react';
import ToolTip from '../../components/ToolTip';
import defaultCategories from '../../utils/defaultCategories';
import './SelectCategory.css';

class SelectCategory extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            categoryList: defaultCategories,
        };
    }

    getCategories() {
        const { categoryList } = this.state;

        return (
            <div className="categories">
                {
                categoryList.map((category, i) => {
                        return (
                            <button id={category.Id} className="category-btn" key={i} onClick={() => this.handleCategorySelect(category.name)}>
                                {category.name}
                            </button>
                        );
                    })
                }
            </div>
        );
    }

    handleCategorySelect = (name) => {
        const { updateStep, updateItem } = this.props;
        updateItem("category", name);
        updateStep(3);
    }

    render() {

        return ( 
        <div className="categories-container">
            <h1>What Are You Selling?</h1>
            <p>Fill out the form for a quick and easy way to get your gear evaluated. If you’d rather come into the store and talk to us directly, we’re here anytime (during store hours, of course).</p>
            <p>(Select One)</p>
            {this.getCategories()}
            <ToolTip btnText="View non-qualifying items for trade-in"
                    headerText="Promotion Exclusions"
                    toolTipId="promo-exclusions" />
        </div>
        );
    }
}

export default SelectCategory;