import React, { useRef, useEffect, useState } from "react";
import NextButton from '../../components/NextButton';
import SelectCategory from '../SelectCategory/SelectCategory';
import './Home.css';
import ToolTip from "../../components/ToolTip";
import FAQ from "../../components/FAQ";
import AddItem from '../../components/AddItem/AddItem';


function Home(props){

        return (
            <div>
                <div className="splash-container">
                    <p className="splash-header">It’s Never Been Easier</p>
                    <p className="splash-text">to sell or trade in your gear</p>
                    <AddItem {...props}/>
                </div>
            </div> 

        );
    
}

export default Home;