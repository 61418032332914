const getFAQ = function(id) {

    switch(id) {
        case ('FAQ-1'):
            return (
               <span>We buy used guitars, basses, drums, amps, keys and MIDI, folk and traditional instruments, recording gear, effect pedals, DJ gear, live sound equipment and more. <br/><br/>
               <span style={{fontWeight: "bold"}}>However, the following items do not qualify for trade-in:</span><br/>
               Acoustic pianos, custom-built cables or snakes, copyrighted material (software, books, videos and DVDs), computers and consumer electronics, snakes and switching systems, analog tape recorders, wireless units, guitar parts, strings, drum sticks and drum heads, upright acoustic basses, large recording and live sound mixers, studio furniture, lighting gear, drum hardware (low end), turntables (other than Technics), stand-alone digital hard disk recorders, DAT and CD recorders, and band and orchestra instruments.</span>
            );
        case ('FAQ-2'):
            return (
                <span>Yes, Guitar Center pays up to $1,000 in cash* for used and vintage gear. However, because of legal restrictions, these stores do not pay out cash: Greensboro, NC and Rockville, MD.</span>
            );
        case ('FAQ-3A'):
            return (
                <span>You do not need an appointment to come in and sell or trade in your used and vintage gear. We’ll buy your gear anytime—during open store hours. You can also check our website for information about upcoming Used Gear Buying Events. However, you can begin the process by filling out the online form with more information about your gear, and we’ll contact you.</span>
            );
        case ('FAQ-3B'):
            return (
                <span>You do not need an appointment to come in and sell or trade in your used and vintage gear. We’ll buy your gear anytime—during open store hours. You can also check our website for information about upcoming Used Gear Buying Events.</span>
            );
        case ('FAQ-4'):
            return (
                <span>Once you’ve filled out the online form telling us more about your gear, an associate will give you a call to gather a few more details. Then, you’ll bring your gear into the store for an expert evaluation and, if an offer is made and you accept, you’ll walk out with payment that same day.</span>
            );
        case ('FAQ-5'):
            return (
                <span>Guitar Center has decades of experience fairly evaluating used and vintage gear for purchase. When you bring your instrument in, an associate will evaluate the piece based on several factors, including condition, historical sales data, market desirability and current stock levels. <br/>For vintage gear, we have a dedicated group of experts who are available during all Guitar Center open hours to assist in evaluations. We take pride in our acquisition process and are firmly committed to making fair market wholesale offers.</span>
            );
        case ('FAQ-6'):
            return (
                <span>You’ll get paid on the same day that you sell your gear. You can also apply your trade-in credit to upgrade to something new.</span>
            );
        case ('FAQ-7'):
            return (
                <span>Once you’re at the store, an associate will evaluate your gear and assess the item(s) based on condition of the piece, historical sales data, market desirability and current stock levels. If we make an offer and you accept it, we’ll pay for your gear same-day.</span>
            );
        case ('FAQ-8'):
            return (
                <span>All payments for gear sold at Guitar Center are made same-day. We pay up to $1,000 in cash* for used and vintage gear. Anything beyond that will be paid with a check<sup>†</sup> or Guitar Center Visa<sup>®</sup> Prepaid Card.**</span>
            );
        case ('FAQ-9'):
            return (
                <span>Unfortunately, Guitar Center does not buy gear without an in-hand inspection at one of our locations.</span>
            );
        case ('FAQ-10'):
            return (
                <span>Yes, if you want to trade in your gear for something else, you can use the credit toward your purchase.</span>
            );
        default:
          // code block
      }
}

export default getFAQ;