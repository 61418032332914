import React from 'react';
import InStoreUploader from '../../components/InStoreUploader';
import './UploaderView.css';
import { Button } from 'react-bootstrap';
import API from "../../utils/API";

class UploaderView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            Main: "",
            Top: "",
            Back: "",
            Left: "",
            Right: "",
            Bottom: "",
            productId: "",
            submitted: false,
            imagesArray: [{id:"Main"}, {id:"Top"}, {id:"Back"}, {id:"Left"}, {id:"Right"}, {id: "Bottom"}],
            viewsArray: ['Main', "Top", "Back", "Left", "Right", "Bottom"],
            currentIndex: 0,
            isUploading: false,
            uploadingErr: false
        };
    }

    componentDidMount() {
       if(window.location.search && window.location.search.length) {
           let itemID = window.location.search.split("?itemId=").pop();
           this.setState({productId: itemID});
       }
    }

    updateImageURL = (file, id) => {
            const {currentIndex} = this.state;
            let newImagesArray = this.state.imagesArray;
            const index = newImagesArray.findIndex(item => item.id === id);
            newImagesArray[index].url = file.preview;
            newImagesArray[index].fileId = file.id;
            // newImagesArray[index].data = file.data;
            this.setState({imagesArray: newImagesArray, currentIndex: currentIndex+1});
            this.setState({[id]: file.preview});
    }
    updateProgress = (fileId, progress) => {
        const {currentIndex} = this.state;
        let newImagesArray = this.state.imagesArray;
        const index = newImagesArray.findIndex(item => item.fileId === fileId);
        newImagesArray[index].progress = progress;
        this.setState({imagesArray: newImagesArray});
    }

    removeImage = (fileId, url) => {
        // on delete click, open modal to select new image, then replace old image
        const images = this.state.imagesArray;
        for (let i = 0; i < images.length; i++) {
            if (fileId === images[i].fileId) {
                images[i].url = url;
            }
        }
        this.setState({imagesArray: images});
    }

    updateValue = (e) => {
        const name = e.target.name;
        const val = e.target.value;
        this.setState({[name]: val});
    }
    handleUpload = (state, success) => {
        // todo: add API post request and show confirmation view
        if (success) {
            let imageSrcs = [];
            for (let i = 0; i < success.length; i++) {
                imageSrcs.push(success[i].uploadURL);
            }
            const data = {
                jsonStr: {
                    id: this.state.productId,
                    images: imageSrcs.reverse()
                }
              };
              API.uploadImages(data).then((res) => {
                this.setState({isUploading: state});
                if (res.status != 200) {
                    this.setState({uploadingErr: true});
                }
            });
            this.setState({imagesArray: success, submitted: true});
        } else {
            this.setState({isUploading: state});
            
        }
    }
    clearState = () => {
        window.location.reload();
    }
    getThumbs = () => {
        const { imagesArray } = this.state;
            return imagesArray.map((img, i) => {
                const label = img.meta.viewId;

                if (img && img.uploadURL) {
                    return (
                    <div>
                        <label htmlFor="image-uploader">{label}</label>
                        <div className="thumbnail-container">
                            <img src={img.uploadURL} />
                        </div>
                    </div>
                            
                      );
                }   
            })
            
    }

    render() {
        const showUploader = this.state.productId && this.state.productId.length > 3 ? true : false;
        const { currentIndex, viewsArray, imagesArray } = this.state;
        const imgURL = imagesArray[currentIndex];
        const id = viewsArray[currentIndex];
        // check that at least one image is selected 
        const index = imagesArray.findIndex(item => item.url);
        const validUrl = index > -1;
        const disabled = this.state.isUploading || !validUrl;


        if (this.state.submitted) {
            return (
                <div className="main-container">
                   <h2 className="success-message">Uploaded<span className="success-message-icon"></span></h2>
                   <p>Product ID: {this.state.productId}</p>
                   <div className="thumbnails-submitted">
                        {this.getThumbs()}
                   </div>
                   <Button className="next-btn" onClick={()=> this.clearState()}>Submit Another Item</Button>
                </div>
            )
        } else if (this.state.uploadingErr) {
            return (
                <div className="main-container">
                    <h2>Failed to Upload</h2>
                    <p>Looks like something went wrong, please start over</p>
                    <Button className="next-btn" onClick={()=> this.clearState()}>Retry</Button>
                </div>
            )
        } else {
        return (
        <div className="main-container">
            <h2>Used Intake Image Uploader</h2>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <label htmlFor="id-field">
                        <span>Reference ID</span>
                    </label>
                    <input name="productId" type="text" autoComplete="new-password" value={this.state.productId} onChange={(e)=>this.updateValue(e)}></input>
                    <span data-js-error="year" className="error-msg">Please enter a valid ID</span>
                </div>
            </div>
            <div className="row">
            <div className={`col-lg-12 col-md-12 col-sm-12 uploader-view ${showUploader ? '--showuploader' : null}`}>
                <div name="image-uploader" className="image-uploader">
                    <InStoreUploader productId={this.state.productId} {...this.props} id={id} isUploading={this.state.isUploading} label={viewsArray[currentIndex]} imageURL={imgURL} removeImage={this.removeImage} imagesArray={this.state.imagesArray} updateImageURL={this.updateImageURL} viewsArray={this.state.viewsArray} updateProgress={this.updateProgress} handleUpload={this.handleUpload}/>
                    <div className="image-uploader-preview"> 
                    </div>
                </div>
            
            </div>
            
            {showUploader ? 
            <Button disabled={disabled === true ? 'true' : null} className="next-btn" onClick={()=> this.handleUpload(true)}>{this.state.isUploading ? 'Uploading...' : 'Upload'}</Button> : null}
        </div>
    </div>
        )
    }
    }
}

export default UploaderView;