const defaultCategories = [
    {
        "name": "Guitars",
        "Id": "guitars-category"
      },
      {
        "name": "Basses",
        "Id": "basses-category"
      }, 
      {
          "name": "Drums",
          "Id": "drums-category"
      }, 
      {
          "name": "Amps",
          "Id": "amps-category"
      }, 
      {
          "name": "Keys & Controllers",
          "Id": "keys-category"
      }, 
      {
          "name": "Folk & Traditional",
          "Id": "folk-category"
      }, 
      {
          "name": "Recording",
          "Id": "recording-category"
      }, 
      {
          "name": "Effects",
          "Id": "effects-category"
      }, 
      {
          "name": "DJ",
          "Id": "DJ-category"
      },
      {
          "name": "Live Sound",
          "Id": "live-category"
      }, 
      {
          "name": "Other",
          "Id": "other-category"
      }
];

export default defaultCategories;