import React from 'react';
import Home from './Home/Home';
import SelectCategory from './SelectCategory/SelectCategory';
import SubmitDetails from './SubmitDetails/SubmitDetails';
import InventoryList from './InventoryList/InventoryList';
import YourInformation from './YourInformation/YourInformation';
import Confirmation from './Confirmation/Confirmation';
import API from '../utils/API';
import moment from 'moment-timezone';
import {validateForm} from '../utils/validation';
import { Alert } from 'react-bootstrap';
import UploaderView from './UploaderView/UploaderView';


const itemSubmitted = new Event('itemSubmitted');
const itemsConfirmed = new Event('itemsConfirmed');
const formSubmitted = new Event('formSubmitted');

class Main extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            currentItem: {},
            currentStep: 1,
            brandsList: [],
            userInfo: {},
            confirmationId: '',
            sellerOption: '',
            isReschedule: false,
            storedUserInfo: {},
            showAlert: false,
            selectedFromList: false,
            uploaderView: true
        };
    }

    componentDidMount() {
        API.getBrands().then((res) => {
            if (res) {
                this.setState({brandsList: res});
            }
        });
    }
        
    validateForm = (num) => {
        const parentId = num === 4 ? 'gear-info-form' : 'your-info-form';
        const parentEl = document.getElementById(parentId);
        const inputElements = parentEl.querySelectorAll("input");
        let isValid = true;

        return inputElements.forEach((element, i) => {
            const name = element.getAttribute('name');
            const errorEl = parentEl.querySelector(`[data-js-error=${name}]`);
            if (element.getAttribute('valid') === 'false') {   
                if (errorEl) {
                    errorEl.setAttribute('data-show', 'true');
                }
                isValid = false;
            } else {
                if (errorEl) {
                    errorEl.setAttribute('data-show', 'false');
                }
            }
            if (i === inputElements.length-1) {
                return isValid;
            }
        });
    }

    getCustomerInfo = (id) => {
        API.getCustomerInfo(id).then((res) => {
            this.updateStep(4);
            this.setState({isReschedule: true,confirmationId: res.id, storedUserInfo: res, items: res.gear});
            
        });
    }

    updateStep = (num, submitItem, userInfo, validate, option, selectedFromList) => {
        if (option) {
            this.setState({sellerOption: option});
        }
        if (num === 5) {
            document.dispatchEvent(itemsConfirmed);
        }
        if (selectedFromList) {
            this.setState({selectedFromList: selectedFromList});
        }
        const itemsList = this.state.items;
        const currentItem = this.state.currentItem;
        window.scrollTo(0, 0);
        if (validate) {
            let isValid;
            return validateForm(num).then(res => {
                isValid = res;
                if (isValid === false) {
                    this.setState({showAlert: true});
                    return;
                } else {
                    this.setState({showAlert: false});
                    document.dispatchEvent(itemSubmitted);
                    this.updateStep(num, submitItem, userInfo);
                }
            });
        }

        if (submitItem) {
            const length = itemsList.length;
            const itemId = length;

            if (currentItem.lineItemId == undefined) {
                currentItem.lineItemId = itemId;
                itemsList.push(currentItem);

                this.setState({
                    items: itemsList, 
                    currentStep: num,
                    currentItem: {}
                });
            }
            else {
                const index = itemsList.findIndex(item => item.lineItemId === currentItem.lineItemId),
                      items = [...this.state.items]; // important to create a copy, otherwise you'll modify state outside of setState call
                items[index] = currentItem;
                this.setState({items, currentStep: num, currentItem: {}});
            }
        } else {
            if (num === 6) {

                this.setState({userInfo: userInfo});
                this.submitDetails(userInfo);
            } else {
                this.setState({currentStep: num});
            }
        }
    }

    submitDetails = (userInfo) => {

        const { items } = this.state;
        const gearList = items.map((item, i) => {
            const imagesArray = item.images;
            const newImageArray = [];
            let newObj = item;
            if (imagesArray && imagesArray.length) {
                for (let p = 0; p < imagesArray.length; p++) {
                    newImageArray.push(imagesArray[p].uploadURL);
                    newObj.images = newImageArray;
                } 
            }
            return newObj;
        });

        let preferredStoreAddress = {};
        let data = {};

        if (this.state.isReschedule) {

            preferredStoreAddress = {
                addressLine1: userInfo.selectedStore.address,
                addressLine2: '',
                addressCity: userInfo.selectedStore.city,
                addressState: userInfo.selectedStore.countryCode,
                addressZip: userInfo.selectedStore.zip
            };
            data = {
                id: this.state.confirmationId,
                name: this.state.storedUserInfo.name,
                email: this.state.storedUserInfo.email,
                phone: this.state.storedUserInfo.phone,
                storeAddress: preferredStoreAddress,
                storePhone: userInfo.selectedStore.phone,
                storeId: userInfo.selectedStore.id,
                storeHours: userInfo.preferredStoreHours,
                submittedDate: moment(new Date()).tz('America/Los_Angeles').format('YYYY-MM-DD'),
                subscribeEmail: userInfo.subscribeEmail,
                gear: gearList,
                status: "updated",
                rescheduled: "yes",
                scheduledDate: userInfo.scheduledDate,
                scheduledTime: userInfo.scheduledTime,
                contactChosenOption:'walkin'

            };
              
        } else {
            preferredStoreAddress = {
                addressLine1: userInfo.selectedStore.address,
                addressLine2: '',
                addressCity: userInfo.selectedStore.city,
                addressState: userInfo.selectedStore.countryCode,
                addressZip: userInfo.selectedStore.zip
            };
            data = {
                name: userInfo.name,
                email: userInfo.email,
                phone: userInfo.phone,
                storeAddress: preferredStoreAddress,
                storePhone: userInfo.selectedStore.phone,
                storeId: userInfo.selectedStore.id,
                storeHours: userInfo.preferredStoreHours,
                submittedDate: moment(new Date()).tz('America/Los_Angeles').format('YYYY-MM-DD'),
                subscribeEmail: userInfo.subscribeEmail,
                gear: gearList,
                lastUpdatedBy: "ATG"            };

            if (this.state.sellerOption == 'OPTION-A') {
                data.contactChosenOption = 'walkin';
                data.scheduledDate = userInfo.scheduledDate;
                data.scheduledTime = userInfo.scheduledTime;
                data.status = "scheduled";

            } else {
                data.contactChosenOption = userInfo.contactChosenOption;
                data.preferredTime = userInfo.contactBestTime;
                data.status = "submitted";

            }
        }

        document.dispatchEvent(formSubmitted);

        API.submitItems(data).then((res) => {

            if (res && res.data && res.data.id) {
                this.setState({
                    currentStep: 6, 
                    confirmationId: res.data.id
                });
            }
        });

    }

    updateItem = (key, value) => {
        this.setState(prevState => ({
            currentItem: {               
                ...prevState.currentItem,
                [key]: value   
            }
        }));
    }

    deleteItem = (id) => {
        const itemsList = this.state.items;
        const updatedItemsList = itemsList.filter(function(el) { return el.lineItemId != id; }); 

        this.setState({items: updatedItemsList});
        if (!updatedItemsList.length) {
            this.handleClose();
        }
    }


    editItem = (id) => {
        if (id < 0) {
            this.setState({currentItem:{}});
        } else {
            const itemsList = this.state.items;
            const selectedItem = itemsList.filter(function(el) { return el.lineItemId == id; }); 
            this.setState({currentItem: selectedItem[0] });
        }

    }

    updateImagesArray = (images) => {

        this.setState(prevState => ({
            currentItem: {               
                ...prevState.currentItem,
                images: images  
            }
        }));
    }

    handleClose = () => {
        this.setState({
            items: [],
            currentItem: {},
            userInfo: {},
            confirmationId: ''
        });
        this.updateStep(1);
       // localStorage.clear();
    }

    setAlert = (state) => {
        this.setState({showAlert: state});
    }

    render() {
        const { currentStep, uploaderView } = this.state;
        const showListButton = (currentStep === 2 || currentStep === 3 )  && this.state.items && this.state.items.length > 0;
        const itemCount = this.state.items ? this.state.items.length : 0;
  
        if (uploaderView) {
            return (
                <div>
                    <UploaderView 
                        selectedFromList={this.state.selectedFromList}
                        updateItem={this.updateItem}
                        updateImagesArray={this.updateImagesArray}
                        editItem={this.editItem}
                        deleteItem={this.deleteItem}
                        items={this.state.items}
                        updateStep={this.updateStep}
                        currentItem={this.state.currentItem}
                        handleClose={this.handleClose}/>
                </div>
            )

        } else {
         return ( 
            <div>
                { currentStep > 1 ? <div className="modal-overlay"></div>: null }
                { this.state.showAlert ? <Alert variant="primary" onClose={() => this.setAlert(false)} dismissible>
                    <span style={{display: "block", textAlign: "center"}}>Required fields missing</span> 
                </Alert> : null }
                
                {
                 currentStep === 1 ?
                    <Home 
                        updateStep={this.updateStep}
                        updateItem={this.updateItem} /> : 
                    (
                         currentStep === 2 ? 
                         <SelectCategory 
                            updateStep={this.updateStep} 
                            updateItem={this.updateItem} /> :
                         (
                                 currentStep === 3 ?
                                 <SubmitDetails
                                    brandsList={this.state.brandsList}
                                    updateImagesArray={this.updateImagesArray}
                                    updateStep={this.updateStep}
                                    updateItem={this.updateItem}
                                    currentItem={this.state.currentItem} /> :
                                 (
                                         currentStep === 4 ?
                                         <InventoryList
                                            selectedFromList={this.state.selectedFromList}
                                            updateItem={this.updateItem}
                                            updateImagesArray={this.updateImagesArray}
                                            editItem={this.editItem}
                                            deleteItem={this.deleteItem}
                                            items={this.state.items}
                                            updateStep={this.updateStep}
                                            currentItem={this.state.currentItem}
                                            handleClose={this.handleClose} />
                                             :
                                         (
                                             currentStep === 5 ? 
                                             <YourInformation
                                                isReschedule={this.state.isReschedule}
                                                confirmationId={this.state.confirmationId} 
                                                sellerOption={this.state.sellerOption}
                                                userInfo={this.state.userInfo}
                                                updateStep={this.updateStep}
                                                handleClose={this.handleClose}/>
                                                 : 
                                                (
                                                    currentStep === 6 ? 
                                                    <Confirmation 
                                                        userInfo={this.state.userInfo}
                                                        items={this.state.items}
                                                        confirmationId={this.state.confirmationId}
                                                        updateStep={this.updateStep} getCustomerInfo={this.getCustomerInfo}
                                                        handleClose={this.handleClose}/> : 
                                                    <Home updateStep={this.updateStep}
                                                    /> 
                                                )
                                         )
                                            // todo: create last submit page (step 5)
                                 )
                         )
                    )
                }
            </div>
            );
        }
     }
}

export default Main;