import axios from 'axios';
import { restElement } from '@babel/types';

function sortList(a, b) {
    const aKey = a.Brand ? a.Brand : a;
    const bKey = b.Brand ? b.Brand : b;
    var ax = [], bx = [];

    aKey.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
    bKey.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });

    while(ax.length && bx.length) {
        var an = ax.shift();
        var bn = bx.shift();
        var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
        if(nn) return nn;
    }
   
    return ax.length - bx.length;
}

const API = {

    getCategories: function() {

        return axios.get('/categories.json').then(function(res) {
            if (res.data) {
                return res.data.categories;
            }
      });

    },

    getBrands: function() {
    return axios.get(process.env.REACT_APP_UVM_API_URL+ process.env.REACT_APP_UVM_BRANDS).then(function(res) {
        if (res.data && res.data.payload) {
            const payload = res.data.payload;
            return payload.sort(sortList);
        }
  })

    },

    getModels: function(brand) {
        // make an axio call to get the models for a specific brand
        // TODO: update param to use brand, using Gibson for testing
        const brandUrl = process.env.REACT_APP_UVM_API_URL + process.env.REACT_APP_UVM_MODELS + `${brand}`;
        return axios.get(brandUrl).then(function(res) {
            if (res.data && res.data.payload) {
                const payload = res.data.payload;
                return payload.sort(sortList);
            }
      })
    
    },

    //todo: add error handlers 
    submitItems: function(data) {
        // axios POST request to submit form data 
        return axios.post(process.env.REACT_APP_UVM_API_URL + process.env.REACT_APP_UVM_USED_INTAKE, data).then(function(res) {
            if (res) {
                return res;
            }
        })
    }, 

    getStores: function(zip, distance) {
        const liveApiYextUrl = process.env.REACT_APP_YEXT_LIVE_API_URL + `&location=${zip}&radius=${distance}&&filters=[{'closed':'false'}]`;
        return axios.get(liveApiYextUrl).then(function(res) {
            if(res.data) {
                return res.data.response;
            }
        })
    },

    getCustomerInfo(id) {
        const url = process.env.REACT_APP_UVM_API_URL + process.env.REACT_APP_UVM_CUSTOMERS + id;

        return axios.get(url).then(function(res) {
            if (res.data) {
                return res.data.payload;
            }
        })
    },
    typeAheadSearch: function(search) {
        const url = process.env.REACT_APP_ATG_QA_URL + process.env.REACT_APP_TYPE_AHEAD + search;
        return axios.get(url).then(function(res) {
            if (res && res.data) {
                return res.data.products;
            }
      });
    },
    uploadImages: function(data) {
        const headers = {
            'Content-Type': 'application/json;charset=UTF-8',
        };
        
        const url = process.env.REACT_APP_UVM_QA_IMAGES_URL;
        return axios.post(url, data, {
            headers: headers
          }).then(function(res) {
            if (res) {
                console.log(res);
                return res;
            }
        });
    }
};

export default API;