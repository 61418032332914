import React, { Component } from "react";
import Uppy from "@uppy/core";
import Webcam from "@uppy/webcam";
import { DashboardModal  } from "@uppy/react";
import { v4 as uuidv4 } from "uuid";
import AwsS3 from "@uppy/aws-s3";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import '@uppy/webcam/dist/style.css';
import { validation, validateField } from '../utils/validation';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Spinner } from 'react-bootstrap';
// import Compressor from 'compressorjs';
import moment from 'moment';


class InStoreUploader extends Component {
  constructor(props) {
    const { productId } = props;
    super(props);
      this.state = {
        modalOpen: false, 
        currentItem: [],
        removeImageId: null,
        selectedId: '',
      }

    this.uppy = Uppy({
      id: 'uppy-2',
      autoProceed: false,
      closeAfterFinish: true,
      allowMultipleUploads: true,
      debug: false,
      proudlyDisplayPoweredByUppy: false,
      hideUploadButton: true,
      locale: {
        strings: {
          myDevice: "Upload"
        }
      },
      onBeforeFileAdded: (currentFile, files) => {
        const { productId } = this.props; 
        const { selectedId } = this.state;
        let view = '_a'; 

        switch (selectedId) {
          case "Main":
            view = "_a";
            break;
          case "Top":
            view = "_b";
            break;
          case "Back":
            view = "_c";
            break;
          case "Left":
            view = "_d";
            break;
          case "Right":
            view = "_e";
            break;
          case "Bottom":
            view = "_f";
            break;
        }
        let fileExtention = currentFile.name;
        fileExtention = fileExtention.split(".").pop();
        const timeStamp = moment().valueOf(); 
        const fileName = `${productId}/${timeStamp}${view}.${fileExtention}`;
        
        const modifiedFile = {
          ...currentFile,
          name: fileName,
          meta: {
            viewId: selectedId
          }
        }

        this.handleClose();        
        return modifiedFile;
        
        // new Compressor(currentFile.data, {      
        //   quality: 0.8,
        //   convertSize: 1000000,
        //   success: (compressedResult) => {
        //     // compressedResult has the compressed file.
        //     // Use the compressed file to upload the images to your server.
        //     //setCompressedFile(res)
        //     const modifiedFile = {
        //       ...currentFile,
        //       name: productId + '/' + currentFile.name ,
        //       meta: {
        //         viewId: this.state.selectedId
        //       },
        //       data: compressedResult
        //     }
        //     this.handleClose();        
        //     return modifiedFile;
        //   },
        // });
        
      },
      restrictions: {
        maxNumberOfFiles: process.env.REACT_APP_UVM_MAX_NO_OF_FILES,
        allowedFileTypes: ['.jpg', '.jpeg'],
        maxFileSize: process.env.REACT_APP_UVM_MAX_IMAGE_FILE_SIZE
      }
    })
    .use(Webcam, {
      countdown: false,
      modes: ["picture"], 
      mirror: false,
      videoConstraints: {
        facingMode: 'environment',
        height: { min: 720, ideal: 1280 },
        width: { min: 480, ideal: 800 },
        aspectRatio: 1.7777777778
      }
    });

    this.uppy.use(AwsS3, {
      companionUrl: process.env.REACT_APP_COMPANION_URL
    })

    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleRemove = this.handleRemove.bind(this)

  
    this.uppy.on('onBeforeFileAdded', (file)=> {
      //('before file added')
    })

    this.uppy.on('thumbnail:generated', (file, preview) => {
      if (this.state.removeImageId) {
        this.props.removeImage(this.state.removeImageId, preview);
        this.uppy.removeFile(this.state.removeImageId);
        this.setState({removeImageId: null});

      } else {
        this.props.updateImageURL(file, this.state.selectedId);  
      }
    })

    this.uppy.on("complete", result => {
      const { updateImageURL, id } = this.props;
      if (result.successful) {
        console.log(result);
        this.props.handleUpload(false, result.successful);
        
      } else {
        this.props.handleUpload(false);
      }
    // updateImageURL(result.successful[0].uploadURL, id);
    });
    
    this.uppy.on("error", error => {
      console.error(error.stack)
      this.props.handleUpload(false);
    });
 }
  
 componentWillUnmount() {
   this.uppy.close();
 }
 handleOpen (id) {
  this.setState({
    modalOpen: true
  });
  // show file uploader instead of image previews in modal
  const addMoreBtn = document.querySelector('.uppy-DashboardContent-addMore');
  if (addMoreBtn) {
    addMoreBtn.click();
  }
  this.setState({selectedId: id});

  // The below line is only added for not showing the Camera button when accessing UVMPhotoApp from UVM application from mobile only.
  if (window.location !== window.parent.location){
    document.querySelector('#gc-used-intake > div > div > div > div:nth-child(3) > div > div > div.image-uploader-container > div:nth-child(2) > div > div.uppy-Dashboard-inner > div > div.uppy-Dashboard-AddFiles > div.uppy-Dashboard-AddFiles-list > span > div > button').style.display = 'none'; 
  }
}

handleClose () {
  this.setState({
    modalOpen: false
  })
}

handleRemove (fileId) {
  this.setState({removeImageId: fileId});
  this.handleOpen();
}

createThumbnails = () => { 
  const { imageURL, id, imagesArray, viewsArray } = this.props;

        return imagesArray.map((img, i) => {
          if (img.url) {
            return (
              <div>
              <label htmlFor="image-uploader">{img.id}</label>
              <div className={`thumbnail-container ${this.props.isUploading ? "--uploading" : ""}`}>
                <button onClick={()=>this.handleRemove(img.fileId)} image-id={img.id} className="uppy-u-reset uppy-Dashboard-Item-action uppy-Dashboard-Item-action--remove" type="button" aria-label="Remove file" title="Remove file">
                  <svg aria-hidden="true" focusable="false" className="uppy-c-icon" width="18" height="18" viewBox="0 0 18 18">
                    <path d="M9 0C4.034 0 0 4.034 0 9s4.034 9 9 9 9-4.034 9-9-4.034-9-9-9z"></path><path fill="#FFF" d="M13 12.222l-.778.778L9 9.778 5.778 13 5 12.222 8.222 9 5 5.778 5.778 5 9 8.222 12.222 5l.778.778L9.778 9z"></path>
                    </svg>
                </button>
                {this.props.isUploading ? <div className="spinner-container">
                <Spinner animation="border" role="status" className="image-loading-spinner">
                  <span className="sr-only">Loading...</span>
                </Spinner>
                </div> : null}
                
                
                <img src={img.url} />
              </div>
              </div>
              
            );
          } else {
            if (this.props.isUploading) {
              return;
            } else {
              return (
                <div>
                  <label htmlFor="image-uploader">{img.id}</label>
                  <button className="image-uploader-modal-btn" disabled={this.props.isUploading === true ? 'true' : null} id={this.props.id} onClick={()=>this.handleOpen(img.id)}></button>
                </div>
                )
            }
          }
            
        })
}

handleSubmit = () => {
  this.uppy.upload();
}

// getButtonThumbs = () => {
//   const { imageURL, id, currentIndex , label, imagesArray } = this.props;
//   return imagesArray.map((img, i) => {
//     return (
//       <div>
//           <label htmlFor="image-uploader">{label}</label>
//           <button className="image-uploader-modal-btn" disabled={this.props.isUploading === true ? 'true' : null} id={this.props.id} onClick={this.handleOpen}></button>
//       </div>
//     )
//   });
// }

 render() {
    const { imageURL, id, currentIndex , label, imagesArray } = this.props;
    let hideAddButton = false;
    if (imagesArray.length === 6) {
      hideAddButton = true;
    }
    if (this.props.isUploading) {
      this.handleSubmit();
    }

   return (
      <div className="image-uploader-container">

        <div className="image-uploader-preview">
            {this.createThumbnails()}
        </div>

        {/* {hideAddButton ? null : this.getButtonThumbs()} */}
        <DashboardModal
          id={"uppy-2"}
          closeModalOnClickOutside
          open={this.state.modalOpen}
          onRequestClose={this.handleClose}
          plugins={["Webcam", "AwsS3"]}
          uppy={this.uppy}
          metaFields={[{ id: 'name', name: 'Name', placeholder: 'file name' }]}
          allowMultipleUploads={true}
          note={"Images only, 5MB or less per image"}
          proudlyDisplayPoweredByUppy={false}
          showProgressDetails={true}
          showLinkToFileUploadResult={false}
          closeAfterFinish
          disableStatusBar
        />
       </div>
   );
 }
}

export default InStoreUploader;
