export const appendScript = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = true;
    document.body.appendChild(script);
    script.addEventListener('load', () => {

        const script = document.createElement("script");
        script.innerHTML = 'POWERREVIEWS.display.render({\n';
        script.innerHTML += 'api_key: "7ace46e7-b415-4f39-a5d3-14e4a1f0c0f1",\n';
        script.innerHTML += 'locale: "en_US",\n';
        script.innerHTML += 'merchant_group_id: "17265",\n';
        script.innerHTML += 'merchant_id: "508516",\n';
        script.innerHTML += 'merchant_user_email: "raghuver.kontham@guitarcenter.com",\n';
        script.innerHTML += 'merchant_user_id: "raghuver.kontham@guitarcenter.com",\n';
        script.innerHTML += 'page_id: "512940",\n';
        script.innerHTML += 'source: "web",\n';
        script.innerHTML += 'style_sheet: "https://static.guitarcenter.com/cmn/brand/gc/productionCss/writeReview.css?vId=hs2020.0a&cb=3",\n';
        script.innerHTML += 'components: {Write: "pr-write"}});\n';
         script.async = true;
        document.body.appendChild(script);                             
      })
}