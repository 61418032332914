import React, { useState } from "react";

import DatePicker from "react-datepicker";
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'
import getDay from'date-fns/getDay'
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";
import './Scheduler.css';

class Scheduler extends React.Component {
    constructor(props) {
      super(props);


      this.state = {
        selectedDate: new Date(),
        minTime: 11,
        maxTime: 7
      };
    }

    addStoreHoursToState = () => {
        return (
            <DatePicker
            id="scheduler"
            minDate={new Date()}
            selected={this.state.selectedDate}
            onChange={this.handleChange}
            onCalendarOpen={this.handleChange}
            onCalendarClose={this.handleClose}
            disabled={this.props.selectedStore && this.props.selectedStore.id ? false : true}
            className={this.props.selectedStore && this.props.selectedStore.id ? 'active-section' : ''}
            timeFormat="HH:mm"
            timeIntervals={30}
            dateFormat="MMMM d, yyyy"
            timeCaption="time"
            minTime={setHours(setMinutes(new Date(), 0), this.state.minTime)}
            maxTime={setHours(setMinutes(new Date(), 30), this.state.maxTime)}
            />
        )
    }
    calculateMinTime = date => {

  
    }

    calculateMaxTime = date => {
   
  
    }

    handleClose = () => {
        this.props.setDateTime(this.state.selectedDate);
    }
  
    handleChange = (date) => {
        const { hoursArray } = this.props;
        const day = getDay(date);
        if (hoursArray.length) {
            if ( day !== 0 && day !== 6)
            {
               this.setState({
                   minTime: hoursArray[1].open,
                   maxTime: hoursArray[1].close-1
               })
            }
            if (day == 6) {
                this.setState({
                    minTime: hoursArray[6].open,
                    maxTime: hoursArray[6].close-1
                })
            }
            else {
                this.setState({
                    minTime: hoursArray[0].open,
                    maxTime: hoursArray[0].close-1
                })
            }
        }

        this.setState({
            selectedDate: date
        })
        
    }


  
    render() {

      return (

        <div>
            {this.addStoreHoursToState()}
        </div>
      );
    }
  }

  export default Scheduler;