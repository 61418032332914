import React from 'react';
import Button from 'react-bootstrap/Button';

function NextButton(props) {
    const { stepNum, updateStep, text, submitItem, validate, id, disabled, option, closeModal, selectedFromList } = props;
    const userInfo = props.userInfo ? props.userInfo : null;

    const handleClick = function() {
      updateStep(stepNum, submitItem, userInfo, validate, option, selectedFromList);
      if (closeModal) {
        closeModal();
      }
    };

    return (
    <Button disabled={disabled === true ? 'true' : null} className="next-btn" id={id ? id : ''} onClick={()=> handleClick()}>{text}</Button>
    );
  }
  export default NextButton;