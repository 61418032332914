import React from 'react';
import NextButton from '../../components/NextButton';
import BackButton from '../../components/BackButton';
// import RecaptchaForm from '../../components/RecaptchaForm';
import './YourInformation.css';
import API from '../../utils/API';
import { validation, validateField } from '../../utils/validation';
import moment from 'moment';
import { formatStoreInfo, formatStoreHours, saveStoreHours, formatHoursForScheduler } from '../../utils/helpers';
import Scheduler from "../../components/Scheduler/Scheduler";
import {ButtonGroup, ToggleButton} from 'react-bootstrap';

class YourInformation extends React.Component {

    constructor(props) {
        super(props);

        this.storeOptions = React.createRef();
        this.submitButton = React.createRef();

        this.state = {
            storesList: [],
            distance: 100,
            zipcode: "",
            selectedStore: {}, 
            geolocation: {},
            locationDistances: [], 
            subscribeEmail: 'no',

            // subscribePhoneNumber: 'no',
            preferredStoreHours: [],
            substep: 1,
            hoursArray: [],
            scheduledDate: new Date(),
            scheduledTime: '',
            contactChosenOption: 'Email',
            contactBestTime: 'Morning',
            comments: '',
            recaptchaValid: true
        };
    }

    componentDidMount() {
        if(navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(a) {
                if (a.coords) {
                    this.setState({geolocation: a.coords});
                    this.searchStores(a.coords);
                }
          }.bind(this));
         }
    }

    searchStores = (geolocation) => {
        const { zipcode, distance } = this.state;
        let location = '';
        if (geolocation && geolocation.latitude && geolocation.longitude) {
            location = `${geolocation.latitude},${geolocation.longitude}`;
        } else {
            location = zipcode;
        }
        API.getStores(location, distance).then((res) => {

            const filteredStores = res.locations.filter(store => !store.id.includes('_'));
            const filteredDistances = res.locationDistances.filter(store => !store.id.includes('_'));
            if (res && res.locations) {
                this.setState({storesList: filteredStores, selectedStore: filteredStores[0], locationDistances: filteredDistances, preferredStoreHours: saveStoreHours(filteredStores[0]), hoursArray: formatHoursForScheduler(filteredStores[0])});
                this.setState({});
                validateField('preferredStore');   
                this.scrollToSection('storeOptions');   
        
            }
        });
    }

    updateValue = (e) => {
        const name = e.target.name;
        const val = e.target.value;
        this.setState({
            [name]: val
        });
        if (name == 'scheduledTime') {
            this.scrollToSection('submitButton');
        }
    }

    scrollToSection = (ref) => {
        setTimeout(() => { 
            this[ref].current.scrollIntoView({behavior: "smooth"});    
        }, 300);
    }

    updateRecaptcha = (value) => {
        if (value === null) {
            this.setState({ recaptchaValid: false });
        } else if (value.length) {
            this.setState({ recaptchaValid: true });
        }
        
    }

    displayStoreList = () => {
        const { storesList, selectedStore, locationDistances } = this.state;

        if (storesList && storesList.length > 0) {
            return (
                <div id="stores-list" ref={this.storeOptions}>
                    {
                        storesList.map((store, i) => {
                        const selected = selectedStore.id === store.id;
                        const index = locationDistances.findIndex(item => item.id === store.id);
                        const distance = index > -1 ? (locationDistances[index].distanceMiles).toFixed(2) : null;
                        const labelMarkup = distance ? `${store.city} - ${distance}mi` : store.city;
                            if (i < 3) {
                                return (
                                    <div key={`store-${i}`} className="store-option" onClick={() => this.updatePreferredStore(store.id)}>
                                        <input type="radio" value={store.id} onChange={() => this.updatePreferredStore(store.id)} checked={selected ? true : false}/>
                                        <label className="store-info-bold">{`${i+1}. ${labelMarkup}`}</label>
                                        <div className="store-info_details" data-show={selected}>
                                            {formatStoreInfo(store)}
                                            <div className="store-hours">
                                            {formatStoreHours(store)}
                                         </div>
                                         </div>
                                    </div>
                                );
                            }
                            
                        })  
                    }
                </div>
            );
        }
        else {
            return (
                <div id="stores-list">
                    <p>No stores found within that radius.</p>
                </div>
            )
        }

    }

    updatePreferredStore = (id) => {
        const storeId = id;
        const { storesList } = this.state;
        const index = storesList.findIndex(item => item.id === storeId);
        
        this.setState({selectedStore: storesList[index]});
        this.setState({preferredStoreHours: saveStoreHours(storesList[index])});
        validateField('preferredStore');
    }

    updateSubscription = (e) => {
        const subscription = e.target.name;
        const val = e.target.checked === true ? "yes" : "no";
        this.setState({[subscription]: val});
    }

    getStoreInfo = () => {
        const { selectedStore } = this.state;
        return formatStoreInfo(selectedStore);
    }

    getStoreHours = () => {
        const { selectedStore } = this.state;
        return formatStoreHours(selectedStore);
    }

    toggleError(e) {
        const name = e.target.name;
        const val = e.target.value;

        if (val && val.length > 2) {
            validateField(name);
        }
    }

    nextStep = (step) => {
        this.setState({substep: step});
    }

    setDateTime = (date) => {
        // add date and time to state and JSON data 
        // add check for date and time selected for moving to final step 
        if (date) {
            this.setState({
                scheduledDate: moment(date).format('YYYY-MM-DD z'),
                // scheduledTime: moment(date).format('h:mm A')
            })
        }

    }

    updatePreferredTime = (val) => {
        this.setState({contactBestTime: val});
   
    }
    updatePreferredContactMethod = (val) => {
        this.setState({contactChosenOption: val});
    }

    render() {
        const selectedStore = this.state.selectedStore ? this.state.selectedStore : '';
        const selectedStoreCity = this.state.selectedStore ? this.state.selectedStore.city : 'Test';
        const userInfo = {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            subscribeEmail: this.state.subscribeEmail,
            subscribePhoneNumber: this.state.subscribePhoneNumber,
            selectedStore: selectedStore,
            preferredStoreHours: this.state.preferredStoreHours,
            contactBestTime: this.state.contactBestTime,
            contactChosenOption: this.state.contactChosenOption,
            scheduledDate: this.state.scheduledDate,
            scheduledTime: this.state.scheduledTime,
            comments: this.state.comments
        };
        const { sellerOption } = this.props;
        const storeSelectedClass = this.state.selectedStore && this.state.selectedStore.id;
        const dateTimeSelectedClass = this.state.scheduledDate && this.state.scheduledTime;
        let validPhoneNumber = this.state.phone && this.state.phone.length ? validation.isValidPhone(this.state.phone) : true;
        if (this.state.contactChosenOption == 'Phone') {
            validPhoneNumber = validation.isValidPhone(this.state.phone);
        }
        const { recaptchaValid } = this.state;

        if (this.props.isReschedule && this.props.confirmationId.length) {
            return (
                <div className="main-container">
                    <a href="#" onClick={this.props.handleClose} className="exit-btn"></a>
                <div className="your-info-container" id="your-info-form">
                    <h2>Reschedule Your Appointment</h2>
                    <div className="your-info-container_form-container_store-info col-md-12 col-sm-12 col-xs-12">
                    <div className="row">
                        <div className="your-info-container_form-container_store-info_location col-md-6 col-sm-6 col-xs-12">
                            {this.getStoreInfo()}
                    </div>
                    <div className="your-info-container_form-container_store-info_hours col-md-6 col-sm-6 col-xs-12">
                    {this.getStoreHours()}
                    </div>
                    <div className="your-info-container_form-container_store-info_locator col-md-12 col-sm-12 col-xs-12 d-flex flex-wrap">
                            <div className="">
                                <label htmlFor="distance">Search within:</label>
                                <input type="number" id="distance" name="distance" min="1" max="100" onChange={(e)=>this.updateValue(e)} value={this.state.distance}/> 
                                <span>miles of</span>
                            </div>
                            <div className="">
                                <label htmlFor="distance">Zip Code</label>
                                <input autoFocus type="number" id="zipcode" name="zipcode" onChange={(e)=>this.updateValue(e)} value={this.state.zipcode}/> 
                            </div>
                            <a href="#" className="store-locator-btn" onClick={this.searchStores}>Apply</a>
                    </div>
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <span data-js-error="preferredStore" className="error-msg">Please select a preferred store</span>
                    </div>
                    <div className="your-info-container_form-container_store-info_stores col-md-12 col-sm-12 col-xs-12">
                            {this.displayStoreList()}
                    </div>
                </div>
                </div>
                </div>
                <div className="your-info-container">
                <Scheduler selectedStore={this.state.selectedStore} hoursArray={this.state.hoursArray} setDateTime={this.setDateTime}/> 
                        <ButtonGroup toggle id="scheduled-time-select" className="mb-2">
                                    <ToggleButton
                                    name="scheduledTime"
                                    variant="outline-dark" 
                                    type="radio"
                                    value={"Morning"}
                                    checked={this.state.scheduledTime === "Morning"}
                                    onChange={(e) => this.updateValue(e)}>Morning (9-12)</ToggleButton>
                                    <ToggleButton
                                    name="scheduledTime"
                                    variant="outline-dark" 
                                    type="radio"
                                    value={"Afternoon"}
                                    checked={this.state.scheduledTime === "Afternoon"}
                                    onChange={(e) => this.updateValue(e)}>Afternoon (12-5)</ToggleButton>
                                    <ToggleButton 
                                    name="scheduledTime"
                                    variant="outline-dark"
                                    type="radio"
                                    value={"Evening"}
                                    checked={this.state.scheduledTime === "Evening"}
                                    onChange={(e) => this.updateValue(e)}>Evening (5-10)</ToggleButton>
                                </ButtonGroup>
                                <NextButton text="Update" id="update-btn" updateStep={this.props.updateStep} userInfo={userInfo} disabled={storeSelectedClass && dateTimeSelectedClass ? null : true} validate={true} submitItem={false} stepNum={6}/>
                                </div>
                               
                </div>
            )
        }

        else if (sellerOption && sellerOption === 'OPTION-A') {
                return (
                    <div className="main-container">
                        <a href="#" onClick={this.props.handleClose} className="exit-btn"></a>
                    <a href="#" class="btn btn-outline-primary" id="back-button" role="button" onClick={()=> this.props.updateStep(4)}>Back</a>
                    <div className="your-info-container">
                        <h2>Where?</h2>
                        <div className="your-info-container_form-container_store-info col-md-12 col-sm-12 col-xs-12">
                        <div className="row">
                            <div className="your-info-container_form-container_store-info_location col-md-6 col-sm-6 col-xs-12">
                                {/* {this.getStoreInfo()} */}
                        </div>
                        <div className="your-info-container_form-container_store-info_hours col-md-6 col-sm-6 col-xs-12">
                        {/* {this.getStoreHours()} */}
                        </div>
                        <div className="your-info-container_form-container_store-info_locator col-md-12 col-sm-12 col-xs-12 d-flex flex-wrap">
                                <div className="">
                                    <label htmlFor="distance">Search within:</label>
                                    <input type="number" id="distance" name="distance" min="1" max="100" onChange={(e)=>this.updateValue(e)} value={this.state.distance}/> 
                                    <span>miles of</span>
                                </div>
                                <div className="">
                                    <label htmlFor="distance">Zip Code</label>
                                    <input autoFocus type="number" id="zipcode" name="zipcode" onChange={(e)=>this.updateValue(e)} value={this.state.zipcode}/> 
                                </div>
                                <a href="#" className="store-locator-btn" onClick={this.searchStores}>search</a>
                        </div>
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <span data-js-error="preferredStore" className="error-msg">Please select a preferred store</span>
                        </div>
                        <div className="your-info-container_form-container_store-info_stores col-md-12 col-sm-12 col-xs-12">
                                {this.displayStoreList()}
                        </div>
                    </div>
                </div>
                {/* <div className="progress-bar">
                    <div className={`percentage-bar ${storeSelectedClass ? '--full' : '--empty'}`}></div>
                </div> */}
                </div>
                    <div className={storeSelectedClass ? 'your-info-container active-section' : 'your-info-container hidden-section'}>
                        <h2 className={storeSelectedClass ? 'active-section' : 'disabled-section'}>When?</h2>
                        <Scheduler selectedStore={this.state.selectedStore} autofocus={storeSelectedClass} hoursArray={this.state.hoursArray} setDateTime={this.setDateTime}/> 
                        <ButtonGroup toggle id="scheduled-time-select" className="mb-2">
                                    <ToggleButton
                                    disabled={!storeSelectedClass}
                                    name="scheduledTime"
                                    variant="outline-dark" 
                                    type="radio"
                                    value={"Morning"}
                                    checked={this.state.scheduledTime === "Morning"}
                                    onChange={(e) => this.updateValue(e)}>Morning</ToggleButton>
                                    <ToggleButton
                                    disabled={!storeSelectedClass}
                                    name="scheduledTime"
                                    variant="outline-dark" 
                                    type="radio"
                                    value={"Afternoon"}
                                    checked={this.state.scheduledTime === "Afternoon"}
                                    onChange={(e) => this.updateValue(e)}>Afternoon</ToggleButton>
                                    <ToggleButton
                                    disabled={!storeSelectedClass} 
                                    name="scheduledTime"
                                    variant="outline-dark"
                                    type="radio"
                                    value={"Evening"}
                                    checked={this.state.scheduledTime === "Evening"}
                                    onChange={(e) => this.updateValue(e)}>Evening</ToggleButton>
                                </ButtonGroup>
                        {/* <div className="progress-bar">
                        <div className={`percentage-bar ${dateTimeSelectedClass ? '--full' : '--empty'}`}></div>
                        </div> */}
                    </div>
                    
                    <div className={dateTimeSelectedClass ? 'your-info-container active-section' : 'your-info-container hidden-section'} ref={this.submitButton}>
                        <h2 className={dateTimeSelectedClass ? 'active-section' : 'disabled-section'}>We'll See You Soon</h2>
                        <div className="your-info-container_form-container row">
                            <div id="your-info-form" className={`your-info-container_form-container_user-form col-md-6 col-sm-6 col-xs-12 ${dateTimeSelectedClass ? 'active-section' : 'disabled-section'}`}>
                                <label htmlFor="name">
                                    <span>Name</span><span className="required-field">*</span>
                                </label>
                                <input disabled={!dateTimeSelectedClass ? true : null} valid={validation.isValidString(this.state.name) ? "true" : "false"} type="text" name="name"  value={this.state.name} onChange={(e)=>this.updateValue(e)} onKeyUp={(e)=>this.toggleError(e)}></input>
                                <span data-js-error="name" className="error-msg">Please enter a valid name</span>
                                <label htmlFor="email">
                                    <span>Email</span><span className="required-field">*</span>
                                </label>
                                <input disabled={!dateTimeSelectedClass ? true : null} valid={validation.isValidEmail(this.state.email) ? "true" : "false"} type="text" name="email"  value={this.state.email} onChange={(e)=>this.updateValue(e)} onKeyUp={(e)=>this.toggleError(e)}></input>
                                <span data-js-error="email" className="error-msg">Please enter a valid email address</span>
                                
                                <label htmlFor="phone">
                                    <span>Phone Number (optional)</span>
                                </label>
                                <input disabled={!dateTimeSelectedClass ? true : null} type="text" name="phone"  value={this.state.phone} onChange={(e)=>this.updateValue(e)}></input>
                                <input type="hidden" name="preferredStore" valid={selectedStore && selectedStoreCity ? "true" : "false"} value={selectedStoreCity}></input>
                                <div className="signup-checkbox">
                                    <input type="checkbox" disabled={!dateTimeSelectedClass ? true : null} name="subscribePhoneNumber" value={this.state.subscribePhoneNumber} onChange={(e)=>this.updateSubscription(e)}></input><span>Text me updates about my used gear</span>
                                </div>
                            </div>
                            <div className="col-sm-6 offset-3 col-xs-12 comments-section">
                                <label htmlFor="comments">Any other comments you'd like to add?</label>
                                <textarea name="comments" id="comments"></textarea>
                            </div>
                            <div className="button-container col-md-12 col-sm-12 col-xs-12">
                                
                                <NextButton text="Book It" updateStep={this.props.updateStep}  userInfo={userInfo} validate={true} submitItem={false} stepNum={6}/>
                            </div>   
                        </div>
                    </div>
                    </div>
                    );

        } else {
            return (
                <div className="main-container">
                    <a href="#" onClick={this.props.handleClose} className="exit-btn"></a>
                <a href="#" class="btn btn-outline-primary" id="back-button" onClick={()=> this.props.updateStep(4)} role="button">Back</a>
                <div className="your-info-container --estimate row">
                <div className="your-info-container_form-container_store-info col-md-6 col-sm-6 col-xs-12">
                    <h3>Select your store</h3>
                            <div className="row">
                                
                                <div className="your-info-container_form-container_store-info_locator col-md-12 col-sm-12 col-xs-12 d-flex flex-wrap">
                                    <div className="">
                                        <label htmlFor="distance">Search within:</label>
                                        <input type="number" id="distance" name="distance" min="1" max="100" onChange={(e)=>this.updateValue(e)} value={this.state.distance}/> 
                                        <span>miles of</span>
                                    </div>
                                    <div className="">
                                        <label htmlFor="distance">Zip Code</label>
                                        <input autoFocus type="number" id="zipcode" name="zipcode" onChange={(e)=>this.updateValue(e)} value={this.state.zipcode}/> 
                                    </div>
                                    <a href="#" className="store-locator-btn --estimate" onClick={this.searchStores}>search</a>
                                </div>
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <span data-js-error="preferredStore" className="error-msg">Please select a preferred store</span>
                                </div>
                                <div className="your-info-container_form-container_store-info_stores col-md-12 col-sm-12 col-xs-12">
                                    {this.displayStoreList()}
                                </div>
                                <div className="your-info-container_form-container_store-info_location col-md-12 col-sm-12 col-xs-12">
                                {/* {this.getStoreInfo()} */}
                                </div>
                                <div className="your-info-container_form-container_store-info_hours col-md-12 col-sm-12 col-xs-12">
                                    {/* {this.getStoreHours()} */}
                                </div>
                            </div>
                        </div>
                    <div className="col-md-6 col-sm-6 col-xs-12" id="your-info-form">
                        <h3>We’ll have someone at {this.state.selectedStore && this.state.selectedStore.city  ? this.state.selectedStore.city : 'your preferred store'} reach out</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <label htmlFor="email">
                                    <span>Email</span>
                                </label>
                                <input valid={validation.isValidEmail(this.state.email) ? "true" : "false"} type="text" name="email"  value={this.state.email} onChange={(e)=>this.updateValue(e)} onKeyUp={(e)=>this.toggleError(e)}></input>
                                <span data-js-error="email" className="error-msg">Please enter a valid email address</span>
                            </div> 
                            <div className="col-sm-12">
                                <label htmlFor="phone">
                                    <span>Phone</span>
                                </label>
                                <input type="text" valid={validPhoneNumber ? "true" : "false"}  name="phone"  value={this.state.phone} onChange={(e)=>this.updateValue(e)} onKeyUp={(e)=>this.toggleError(e)}></input>
                                <span data-js-error="phone" className="error-msg">Please enter a valid phone number</span>
                                <input type="hidden" name="preferredStore" valid={selectedStore && selectedStoreCity ? "true" : "false"} value={selectedStoreCity}></input>
                                
                            </div>
                        </div>
                        
                        <div className="row">
                            <label htmlFor="best-time-select">Preferred Contact Method</label>
                            <div className="col-12 button-group-container">
                                <ButtonGroup toggle id="contact-option-select" className="mb-2">
                                    <ToggleButton 
                                    variant="outline-dark"
                                    type="radio"
                                    value={"Email"}
                                    checked={this.state.contactChosenOption === "Email"}
                                    onChange={(e) => this.updatePreferredContactMethod(e.currentTarget.value)}>Email</ToggleButton>
                                    <ToggleButton
                                    variant="outline-dark" 
                                    type="radio"
                                    value={"Phone"}
                                    checked={this.state.contactChosenOption === "Phone"}
                                    onChange={(e) => this.updatePreferredContactMethod(e.currentTarget.value)}>Phone</ToggleButton>
                                </ButtonGroup>
                            </div>
                        </div>
                        <div className="row">
                        <label htmlFor="best-time-select">Best time to contact?</label>
                            <div className="col-12 button-group-container">    
                                <ButtonGroup toggle id="best-time-select" name="best-time-select" className="mb-2">
                                    <ToggleButton
                                    variant="outline-dark" 
                                    type="radio"
                                    value={"Morning"}
                                    checked={this.state.contactBestTime === "Morning"}
                                    onChange={(e) => this.updatePreferredTime(e.currentTarget.value)}>Morning (9-12)</ToggleButton>
                                    <ToggleButton
                                    variant="outline-dark" 
                                    type="radio"
                                    value={"Afternoon"}
                                    checked={this.state.contactBestTime === "Afternoon"}
                                    onChange={(e) => this.updatePreferredTime(e.currentTarget.value)}>Afternoon (12-5)</ToggleButton>
                                    <ToggleButton 
                                    variant="outline-dark"
                                    type="radio"
                                    value={"Evening"}
                                    checked={this.state.contactBestTime === "Evening"}
                                    onChange={(e) => this.updatePreferredTime(e.currentTarget.value)}>Evening (5-10)</ToggleButton>
                                </ButtonGroup></div></div> 
                                <div className="col-12">
                                <label htmlFor="comments">Any other comments you'd like to add?</label>
                                <textarea name="comments" id="comments"></textarea>
                            </div>  
                                         
                    </div>
                            <div className="col-12">
                            {/* <RecaptchaForm updateRecaptcha={this.updateRecaptcha}/> */}
                            <NextButton text="Contact Me" updateStep={this.props.updateStep} userInfo={userInfo} validate={true} submitItem={false} stepNum={6} id="get-estimate-btn" disabled={storeSelectedClass ? null : true}/>                            </div>
                        
                    </div>
            </div>
            )
        }

    }
}

export default YourInformation;