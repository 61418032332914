const getToolTip = function(id) {

    switch(id) {
        case ('condition-input'):
            return (
                <div>
                    <p className="modal-section-header">Excellent:</p>
                    <p className="modal-section-text">Gear is in like-new condition and shows little to no signs of use. Free from blemishes, scratches and user wear. All controls are completely functional.</p>
                    <p className="modal-section-header">Great:</p>
                    <p className="modal-section-text">Item shows little signs of use and all controls are completely functional. It may have some minor surface scratches.</p>
                    <p className="modal-section-header">Good:</p>
                    <p className="modal-section-text">Gear is completely functional and shows some signs of use. It may have some surface scratches, dings and dents.</p>
                    <p className="modal-section-header">Fair:</p>
                    <p className="modal-section-text">Item is functional but shows signs of heavy use, such as scratches, dings, dents, chips and worn parts or controls.</p>
                </div>
            );
        case ('promo-exclusions'):
            return (
                <div>
                    <p className="modal-section-header">Non-qualifying Items for Trade-In</p>
                    <p className="modal-section-text">Acoustic pianos, custom-built cables or snakes, copyrighted material (software, books, videos and DVDs), computers and consumer electronics, snakes and switching systems, analog tape recorders, wireless units, guitar parts, strings, drum sticks and drum heads, upright acoustic basses, large recording and live sound mixers, studio furniture, lighting gear, drum hardware (low end), turntables (other than Technics), stand-alone digital hard disk recorders, DAT and CD recorders, and band and orchestra instruments.</p>
                </div>
            );
        case ('photo-guidelines'):
            return (
                <div>
                    <p className="photo-guidelines-title">Photo Guidelines</p>
                    <p className="photo-guidelines-text">Upload photos that show a clear view of the gear you’re selling. We recommend straight-on shots with good lighting and clutter-free backgrounds.</p>
                    <div className="row">
                        <div className="col-md-6 image-container">
                            <div>
                                <p>Front & Back Shots</p>
                                <div className="guidelines-img" id="image1"></div>
                                <div className="guidelines-img" id="image2"></div>
                            </div>
                            <div>
                                <p>Detailed Shot</p>
                                <div className="guidelines-img" id="image3"></div>
                                <div className="guidelines-img" id="image4"></div>
                            </div>
                        </div>
                        <div className="col-md-6 image-container">
                            <div>
                                <p>Detailed Shot</p>
                                <div className="guidelines-img" id="image5"></div>
                                <div className="guidelines-img" id="image6"></div>
                            </div>
                            <div>
                                <p>Undesirable Cosmetic Flaws</p>
                                <div className="guidelines-img" id="image7"></div>
                                <div className="guidelines-img" id="image8"></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        case ('brand-input'):
            return (
                <div>
                    <p className="modal-section-text">Is it a Fender, a Gibson, an Ibanez or something else? Start typing to find the brand of gear you’re selling here.</p>
                </div>
            );
        case ('model-input'):
            return (
                <div>
                    <p className="modal-section-text">Search or type in to specify the model number or name of your gear (example: Stratocaster, Casino, ES-335, etc.).</p>
                </div>
            );
        case ('color-input'):
            return (
                <div>
                    <p className="modal-section-text">If you know the specific color or finish, insert that here. If you aren’t sure, just be as specific as possible (example: cherry red, deep purple, neon green, gloss finish, etc.).</p>
                </div>
            );
        case ('year-input'):
            return (
                <div>
                    <p className="modal-section-text">Insert the year your gear was released. If you aren’t sure of the exact year, just be as specific as possible (example: ’60s, 1975-1985, 2000s, etc.).</p>
                </div>
            );
        case ('serial-input'):
            return (
                <div>
                    <p className="modal-section-text">You can usually find the Serial Number printed on the back of your gear. On guitars, it’s usually on the back of the headstock for electrics, or in the soundhole on acoustics.</p>
                </div>
            );
        case ('description-input'):
            return (
                <div>
                    <p className="modal-section-text">Share anything else about your gear that’ll help us give it a proper evaluation. Has the gear been modified in any way? Any repairs or issues to note? Plus, include special features like whether it comes with a certificate of authenticity, it’s a limited-edition model, etc.</p>
                </div>
            );
        case ('price-input'):
                return (
                    <div>
                        <p className="modal-section-text">We’d like to know what you’re hoping to get for your gear. Start with the minimum price you’re willing to sell it for, and go up to the desired amount you’d like to get.</p>
                    </div>
                )
        case ('health-safety'):
            return (
                <div className="health-safety-modal">
                    <p className="modal-section-text">The health and safety of our customers and associates is our top priority. We want to ensure you feel safe and comfortable when you're shopping with us.</p>
                    <p className="modal-section-text">We offer multiple ways for you to shop—in our stores, on guitarcenter.com, via the Guitar Center app and with our Call Center. You can also order online and pick up in-store, or via Contactless Curbside Pickup if you'd prefer to pick up your gear outside of the store.</p>
                    <p className="modal-section-text">We've updated our stores with safety efforts to help us serve you in an environment that's safe for everyone.</p>
                    <p className="modal-section-text">To ensure the protection of our customers and associates, we are requiring everyone over the age of 2 years to wear a face mask or adequate face covering when in our stores. While this isn’t an easy decision, we are erring on the side of caution and in accordance with the CDC to protect you and our associates alike. When visiting our stores, we ask that you:</p>
                    <ul className="modal-section-list">
                        <li>Do not enter the store if you are sick or were exposed to COVID-19</li>
                        <li>Wear adequate face coverings or masks</li>
                        <li>Use hand sanitizer provided for your use in stores</li>
                        <li>Practice social distancing by staying six feet away from others</li>
                    </ul>
                    <p className="modal-section-text">To see your local store hours, visit our <a href="https://stores.guitarcenter.com/search.html" target="_blank">store locator page</a>.</p>
                    
                    <p className="modal-section-text">Our Call Center is available at 866-498-7882 from 5:00 a.m. to 8:00 p.m. PDT Monday through Friday, and 6:00 a.m. to 7:00 p.m. PDT weekends. Currently, the best way to reach us is via email at <a href="mailto:service@guitarcenter.com">service@guitarcenter.com</a> and we will reply as quickly as possible.</p>
                    <p className="modal-section-text">In-store music lessons, for the most part, are operating in select stores and we’re expanding to safely open additional stores on an ongoing basis. To see if your store has in-store lessons available, please visit our <a href="https://stores.guitarcenter.com/search.html" target="_blank">store locator page</a>..</p>
                    <p className="modal-section-text">We also offer one-to-one online music lessons with our instructors via the Zoom platform. Our technology presents an opportunity to keep the Guitar Center Lessons program available to students who want to learn or improve their skills from the comfort of home. For more information on our music lessons program, please visit our <a href="https://www.guitarcenter.com/Services/Lessons/" target="_blank">Lessons page.</a></p>
                    <p className="modal-section-text">Thank you for being a Guitar Center customer.</p>
                    <p className="modal-section-text">We truly appreciate the opportunity to serve you and to help #KEEPTHEMUSICGOING.</p>
                </div>
            );
        case ('exit-app'):
            return (
                <div>
                    <p className="modal-section-text">Are you sure you wish to exit? Exiting out now will remove all items from your gear list.</p>
                </div>
            )
        default:
          // code block
      }
}

export default getToolTip;