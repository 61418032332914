import React from 'react';
import './PriceRange.css';
import ToolTip from '../ToolTip';
import { validation, validateField } from '../../utils/validation';

class PriceRange extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showToolTip: false
  }
}

toggleError(e) {
  const name = e.target.name;
  const val = e.target.value;
  if (val && val.length > 2) {
      validateField(name);
  }
}

  render() {
  let { askingMinPrice, askingMaxPrice, updateValue, showToolTip } = this.props;

    return (
      <div className="price-range row">
        <h2 className="col-12">Asking Price
        <ToolTip className="tooltip-icon" showToolTip={showToolTip}
                          btnText="?"
                          headerText="Price"
                          toolTipId="price-input" /></h2>
        <p className="col-12">Please select a range below:</p>
        <div className="col-6 price-field">
          <label htmlFor="askingMinPrice">Minimum</label>
          <input placeholder="" name="askingMinPrice" onKeyUp={(e)=>this.toggleError(e)} onChange={(e)=>updateValue(e)} type="number" valid={validation.isValidPrice(this.props.askingMinPrice) ? "true" : "false"} defaultValue={askingMinPrice} min="0"></input>
        </div>
        <div className="col-6 price-field">
          <label htmlFor="askingMaxPrice">Desired</label>
          <input name="askingMaxPrice" onChange={(e)=>updateValue(e)}  type="number" defaultValue={askingMaxPrice} min={askingMinPrice}></input>
        </div>
        <span data-js-error="askingMinPrice" className="col-12 error-msg">Please enter a minimum price</span>
      </div>
    );
  }
}

export default PriceRange;