import React from 'react';

class Dropdown extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showDropdown: false
        };
    }

    toggleDropdown = (name) => {
        this.props.toggleAllDropdowns(name);
        if (this.state.showDropdown === false) {
            this.setState({showDropdown: true});
        } else {
            this.setState({showDropdown: false});
        }
    }

    showList(e) {
        this.setState({showDropdown: true});
    }

    render() {
        const { currentDropdown, name, value, getList } = this.props;
        const dataOpen = currentDropdown && currentDropdown === name ? this.state.showDropdown : false;

        return (
           <div onClick={()=>this.toggleDropdown(name)} className="filtered-input">
            <input name={name} autoComplete="new-password" autoComplete="off" id="brand-field" type="text" value={value}/>
                <div className="filtered-input_dropdown-toggle" data-open={dataOpen}></div>
                <div className="filtered-input_dropdown" data-open={dataOpen} >
                    {getList()}
                </div>
                <div className="dropdown-backdrop" onClick={()=>{this.setState({showDropdown: false})}} data-open={dataOpen}></div>
           </div>
        )
    }
}

export default Dropdown;