import React from 'react';
import NextButton from '../../components/NextButton';
import './AddItem.css';
import API from '../../utils/API';
class AddItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            searchSuggestions: [],
            searchValueBrand: '',
            searchValueName: '',
            searchFieldValue: '',
            showSuggestions: false,
            selectedFromList: false
        }
    }

    typeAhead = (e) => {
        const { updateItem } = this.props;
        this.setState({searchFieldValue: e.target.value, selectedFromList: false});
        updateItem("name", e.target.value);
        updateItem("category", "Guitars");

        if (e.target.value && e.target.value.length > 2) {
            API.typeAheadSearch(e.target.value).then((res) => {
                if (res) {
                    this.setState({searchSuggestions: res, showSuggestions: true});
                }
            });
        }
    }

    getSearchSuggestions = () => {
        const { searchSuggestions } = this.state;
        if (searchSuggestions && searchSuggestions.length) {
            return searchSuggestions.map((item, i) => {
                return (
                    <div key={`item-${i}`} className="search-suggestions_line" onClick={()=>this.itemSelected(item)}>
                        <p><img className="search-suggestions_line_img" src={item.imageUrl}></img>{item.skuName}</p>
                    </div>
                );
            }) 
        }
    }

    itemSelected = (item) => {
       this.setState({searchValueName: item.skuName, searchValueBrand: item.brandName, searchFieldValue: item.skuName, showSuggestions: false, selectedFromList: true});
       const { updateStep, updateItem } = this.props;
       const category = item.rootCategory ? item.rootCategory : "Guitars";
       updateItem("name", item.skuName);
       updateItem("brand", item.brandName);
       updateItem("category", category);
       
    }

    // handleButtonClick = () => {

    //     updateStep(4);
    // }

    render() {
        const { searchValueName, searchValueBrand, searchFieldValue, showSuggestions } = this.state;
        const disabled = searchFieldValue.length && searchFieldValue.length > 3 ? false : true;
        return (
            <div className="type-ahead-search">
                <input type="text" onChange={(e)=>this.typeAhead(e)} value={`${searchFieldValue}`} placeholder="What are you selling (Ex: Gibson Les Paul)"></input>
                <NextButton text="Go" disabled={disabled} id="homepage-next" selectedFromList={this.state.selectedFromList} updateStep={this.props.updateStep} submitItem={true} stepNum={4} closeModal={this.props.handleCloseModal} {...this.props}/>
                <div className="search-suggestions" data-show={showSuggestions}>{this.getSearchSuggestions()}</div>
            </div>
        )
    }
}

export default AddItem;