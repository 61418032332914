import React, { useEffect } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import './Confirmation.css';
import { formatStoreInfo, formatStoreHours } from '../../utils/helpers';
import moment from 'moment';

class Confirmation extends React.Component {
    
    constructor(props) {
      super(props);

      this.state = {
      };
  }

    getStoreInfo = () => {
        const { userInfo } = this.props;
        return formatStoreInfo(userInfo.selectedStore);
    }

    getStoreHours = () => {
        const { userInfo } = this.props;
        return formatStoreHours(userInfo.selectedStore);
    }

    itemsList = (items) => {
        return items.map((item, i) => {
                return (
                    <div key={`item-${i}`} className="row confirmation-container_gear">
                        <span className="confirmation-container_gear_item-info_bold">{item.brand ? item.brand : ""} {item.model ? item.model : ""}</span>
                        <span className="confirmation-container_gear_item-info">{item.condition ? item.condition : ""} {item.year ? item.year : ""} {item.color ? item.color : ""} {item.serialNumber ? `Serial #${item.serialNumber},` : ""} 
                        {item.askingMinPrice ? `$${item.askingMinPrice}` : ""} {item.askingMaxPrice ? ` - $${item.askingMaxPrice}` : ""}</span>
                    </div>
                );
            })
    }

    getUserInfo = (userInfo) => {
       return (
            <div >
                <p className="confirmation-container_name">{userInfo.name}</p>
                <p className="confirmation-container_email">{userInfo.email}</p>
            </div>
        ); 
    }
    changeAppointment = (id) => {
        this.props.getCustomerInfo(id);
    }

    getAppointmentInfo = (userInfo) => {       
        const formattedDate = moment(userInfo.scheduledDate).format("dddd, MMM Do");
        const formattedTime = userInfo.scheduledTime;
        const selectedStore = userInfo.selectedStore.city;
        const { confirmationId } = this.props;
        const formattedAddress = `${userInfo.selectedStore.address}, ${userInfo.selectedStore.state}, ${userInfo.selectedStore.zip}`;
        return (
            <div className="confirmation-container">
                <h2>We're Ready</h2>
                <p>We're ready to checkout your gear on {formattedDate} @ {formattedTime} in {selectedStore}.</p>
                <a id="get-directions-btn" href={`http://maps.google.com/?q=${formattedAddress}`} className="btn btn-primary confirmation-container_btn" target="_blank">Get Directions</a>
                <h2> What's Next?</h2>
                <p>Bring in your gear and we'll have someone standby to work with you on an appraisal. We'll talk about pricing and if everything looks good, we'll pay you outright.</p>
                <p>If you're looking to trade it in, we can do even better.</p>
                <p>The process typically takes 30-45 minutes.</p>
                {/* <button onClick={()=>this.changeAppointment(confirmationId)} className="btn btn-primary confirmation-container_btn">Reschedule</button> */}
            </div>
        )
    }

    getEstimateView = (userInfo) => {
        return (
            <div className="confirmation-container">
                <a href="#" onClick={this.props.handleClose} className="exit-btn"></a>
                <h2>Message Received</h2>
                <p>One of our experts is taking a look at your gear to get an idea of what we'd typically pay for it, based on condition.</p>
                <h2>What's Next?</h2>
                <p>Expect a response within the next day or so.</p>
            </div>
        )
    }

    render() {
        const { items, confirmationId, userInfo } = this.props;

        if (userInfo.scheduledDate !== "") {
            return (
                <div className="main-container">
                    <a href="#" onClick={this.props.handleClose} className="exit-btn"></a>
                    {this.getAppointmentInfo(userInfo)}                
                </div>     
            )
        } else {
            return (
                <div className="main-container">
                    <a href="#" onClick={this.props.handleClose} className="exit-btn"></a>
                    {this.getEstimateView(userInfo)}
                </div>
            )
        }

        return ( 
        <div className="confirmation-container"> 
            <h1 className="confirmation_header">What's Next?</h1>
            {/* <p>ID#: {confirmationId}</p> */}
            <p>Thanks for choosing to sell or trade in your gear with Guitar Center. We’ll have one of our associates get in touch with you to chat more about your gear and schedule an in-store evaluation. You should have an email confirmation in your inbox soon, or you can print this page for your records. As a note, the asking price you provided helps us evaluate your gear, but until a full appraisal is complete, we cannot commit to any purchase or amount.</p>
            <div className="container">
                <div className="row">
                    <div className="col-md-7">
                    <h4>Preferred store location:</h4>
                        <div className="row">
                            <div className="confirmation-container_store-info col-md-6 col-sm-6 col-xs-12">
                                {this.getStoreInfo()}
                            </div>
                            <div className="confirmation-container_store-hours col-md-6 col-sm-6 col-xs-12">
                            {this.getStoreHours()}
                            </div>
                        </div>
                    </div>
                    <div className="confirmation-container_your-info col-md-5">
                        <h4>Your info:</h4>
                        {this.getUserInfo(userInfo)}
                    </div>
                </div>
                <div className="row">
                    <div className="confirmation-container_your-gear col-md-12">
                        <h4>Your gear:</h4>
                        {this.itemsList(items)}
                    </div>
                </div>
            </div>
        </div>
        );
    }
}
  export default Confirmation;