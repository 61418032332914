import React from 'react';
import { validateField } from '../utils/validation';
class FilteredInput extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showDropdown: false
        };
    }

    toggleDropdown = (name, e) => {
        this.props.toggleAllDropdowns(name);
            if (this.state.showDropdown === false) {
                this.setState({showDropdown: true});
            } else {
                this.setState({showDropdown: false});
            }
        if (name === 'model' && e) {
            if (e.type === 'focus') {
                this.props.getModelsAPI();
            }
        }
    }

    showList(e) {
        const { selectValue } = this.props;
        this.setState({showDropdown: true});
        selectValue(e.target.name, e.target.value);
        this.props.filterList(e);
        validateField(e.target.name);
    }

    scrollItems(e) {
        if (e.keyCode === 40) {
            
        } else if (e.keyCode === 38) {

        }
    }

    render() {
        const { currentDropdown, name, validate, value, getList, placeholder } = this.props;
        const dataOpen = currentDropdown && currentDropdown === name ? this.state.showDropdown : false;
        return (
            <div>
                <div className="filtered-input">
                    <input onFocus={(e)=>this.toggleDropdown(name, e)} name={name} valid={validate ? validate : "true"} autoComplete="new-password" autoComplete="off" placeholder={placeholder} id={`${name}-field`} type="text" onChange={(e)=>this.showList(e)} value={value}/>
                        <div className="filtered-input_dropdown-toggle" data-open={dataOpen} onClick={()=>this.toggleDropdown(name)}></div>
                        <div className="filtered-input_dropdown" data-open={dataOpen} onClick={()=>this.toggleDropdown(name)}>
                            {getList()}
                        </div>
                </div>
                <div className="dropdown-backdrop" onClick={()=>{this.setState({showDropdown: false})}} data-open={dataOpen}></div>
            </div>
           
        )
    }
}

export default FilteredInput;