import React, { Component } from "react";
import Uppy from "@uppy/core";
import Webcam from "@uppy/webcam";
import { DashboardModal  } from "@uppy/react";
import { v4 as uuidv4 } from "uuid";
import AwsS3 from "@uppy/aws-s3";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import '@uppy/webcam/dist/style.css';
import { validation, validateField } from '../utils/validation';



class ImageUploader extends Component {
  constructor(props) {
    const { updateImagesArray } = props;
    super(props);
      this.state = {
        modalOpen: false, 
        currentItem: []
      }

    this.uppy = Uppy({
      id: 'uppy-2',
      autoProceed: false,
      allowMultipleUploads: true,
      debug: false,
      proudlyDisplayPoweredByUppy: false,
      onBeforeFileAdded: (currentFile, files) => {
        const randomId = uuidv4();
        const modifiedFile = {
          ...currentFile,
          name: randomId +'/'+currentFile.name 
        }
        return modifiedFile
      },
      restrictions: {
        maxNumberOfFiles: process.env.REACT_APP_UVM_MAX_NO_OF_FILES,
        allowedFileTypes: ["image/*"],
        maxFileSize: process.env.REACT_APP_UVM_MAX_IMAGE_FILE_SIZE
      }
    })
    .use(Webcam, {
      countdown: false,
      modes: ["picture"], 
      videoConstraints: {
        facingMode: 'environment' 
      }
    });

    this.uppy.use(AwsS3, {
      companionUrl: process.env.REACT_APP_COMPANION_URL
    })

    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleRemove = this.handleRemove.bind(this)

  
    this.uppy.on('onBeforeFileAdded', (file)=> {
      //('before file added')
    })

    this.uppy.on("complete", result => {
      const imageList = [];
      const {currentItem } = this.props;
      const currentImageList = currentItem.images ? currentItem.images : [];

      for (let index = 0; index < result.successful.length; index++) {

        const imageUploadUrl = result.successful[index];
        imageList.push(imageUploadUrl)
      }
      const newImageListArray = (currentImageList).concat(imageList);
      this.props.updateImagesArray(newImageListArray);
      validateField('imageCount');
    })
    this.uppy.on("error", error => {
      console.error(error.stack)
    })
 }


 
 componentWillUnmount() {
   this.uppy.close();
 }
 handleOpen () {
  this.setState({
    modalOpen: true
  })
}

handleClose () {
  this.setState({
    modalOpen: false
  })
}

handleRemove (imageId) {
  const {  currentItem} = this.props;
  const currentItemImagesArray = currentItem.images ? currentItem.images : []; 
  const newCurrentItemImagesArray = currentItemImagesArray.filter(image => image.id !== imageId);
 this.uppy.removeFile(imageId)
 this.props.updateImagesArray(newCurrentItemImagesArray);
}


createThumbnails = () => { 
  const { currentItem } = this.props;
  if (currentItem.images !== undefined) {

    return currentItem.images.map((imgSrc, i) => {
          return (
            <div className="thumbnail-container">
              <button onClick={()=>this.handleRemove(imgSrc.id)} image-id={imgSrc.id} className="uppy-u-reset uppy-Dashboard-Item-action uppy-Dashboard-Item-action--remove" type="button" aria-label="Remove file" title="Remove file">
                <svg aria-hidden="true" focusable="false" className="uppy-c-icon" width="18" height="18" viewBox="0 0 18 18">
                  <path d="M9 0C4.034 0 0 4.034 0 9s4.034 9 9 9 9-4.034 9-9-4.034-9-9-9z"></path><path fill="#FFF" d="M13 12.222l-.778.778L9 9.778 5.778 13 5 12.222 8.222 9 5 5.778 5.778 5 9 8.222 12.222 5l.778.778L9.778 9z"></path>
                  </svg>
              </button>
              <img src={imgSrc.uploadURL} />
            </div>
          );
      })
  }
}

 render() {
   return (
      <div className="image-uploader-container">
         <span data-js-error="imageCount" className="error-msg mb-2 col-sm-12 p-0">Please upload at least 2 images</span>
        <div className="image-uploader-preview">
            {this.createThumbnails()}
        </div>
        <button className="image-uploader-modal-btn" onClick={this.handleOpen}>+</button>
        <DashboardModal
          id={"uppy-1"}
          closeModalOnClickOutside
          open={this.state.modalOpen}
          onRequestClose={this.handleClose}
          plugins={["Webcam", "AwsS3"]}
          uppy={this.uppy}
          metaFields={[{ id: 'name', name: 'Name', placeholder: 'file name' }]}
          allowMultipleUploads={true}
          note={"Images only, 5MB or less per image"}
          proudlyDisplayPoweredByUppy={false}
          showProgressDetails={true}
          showLinkToFileUploadResult={false}
        />
       </div>
   );
 }
}

export default ImageUploader;