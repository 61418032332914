import React from 'react';
import { Switch, Route } from 'react-router-dom';

import WriteReview from '../../components/WriteReview';

const WriteReviewPage = () => {
  return (
    <Switch> {/* The Switch decides which component to show based on the current URL.*/}
      <Route exact path='/write-a-review' component={WriteReview}></Route>
    </Switch>
  );
}

export default WriteReviewPage;