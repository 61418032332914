import React from 'react';
import {appendScript} from '../utils/appendWriteReviewScript'

class WriteReview extends React.Component {

    constructor(props) {
        super(props);
        
    }

    componentDidMount() {
        appendScript("https://ui.powerreviews.com/stable/4.0/ui.js");
       
      }

    render() {

        return (
            <div>
                <div id="reviewsDisplay4">
                    <div id="static_inner" className="segment hasChrome">
                        <div className="chrome "><div className="tls"></div><div className="tr"></div><div className="bls"></div><div className="br"></div></div>
                        <div className="chromeContent">
                            <div className="scrollable">
                                <link href="https://static.guitarcenter.com/cmn/brand/gc/productionCss/writeReview.css?vId=hs2020.0a&amp;cb=3" 
                                    id="pr-style-override" type="text/css" rel="stylesheet"></link>
                                <div id="gcPowerReviews_writeReviewContainer">
                                    <div id="pr-write"> 
                                    </div>
                                </div>					
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        )
    }
}

export default WriteReview;