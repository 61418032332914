import React from 'react';
import Modal from 'react-bootstrap/Modal';
import getToolTip from '../utils/getToolTip';

class ToolTip extends React.Component {
    
    constructor(props) {
      super(props);

      this.state = {
        showToolTip: false 
      };
  }

  handleToolTipShow = () => {
      this.setState({showToolTip: true});
  }

  handleToolTipClose = () => {
      this.setState({showToolTip: false});
  }

    render() {
      const {btnText, headerText, className, toolTipId, exitApp, handleClose} = this.props;
      return (
            <>
              <a href="#" className={`open-tooltip-btn ${className}`} id={toolTipId} onClick={this.handleToolTipShow}>
              {btnText ? btnText : ""}
              </a>
            <Modal show={this.state.showToolTip} onHide={this.handleToolTipClose}>
              <Modal.Header closeButton>
                <Modal.Title>{headerText}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{getToolTip(toolTipId)}</Modal.Body>
              {exitApp ? <div className="button-container"><button className="next-btn" onClick={handleClose}>Confirm</button><button className="back-btn" href="#" onClick={this.handleToolTipClose}>Cancel</button></div> : null}
            </Modal>
            </>
        );
    }
 
  }
  export default ToolTip;