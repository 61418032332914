import React from 'react';
import { Modal, Button, Overlay, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import NextButton from '../../components/NextButton';
import BackButton from '../../components/BackButton';
import './InventoryList.css';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import SubmitDetails from '../SubmitDetails/SubmitDetails';
import AddItem from '../../components/AddItem/AddItem';

class InventoryList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            detailsView: false,
            activeId: null,
            showAddItemModal: false,
            showOverlay: false
        }
    }
    handleCloseModal = () => {
        this.setState({showAddItemModal: false});
    }

    componentDidMount() {
        this.toggleUploadPopover();
    }

    toggleUploadPopover = () => {
        setTimeout(() => { 
            this.setState({showOverlay: true});    
        }, 1000);
        setTimeout(() => { 
            this.setState({showOverlay: false});    
        }, 4000);
    }

    viewDetails = (id) => {
        if (this.state.detailsView == true) {
            if (id == this.props.currentItem.lineItemId) {
                this.setState({detailsView: false, activeId: null});
            } else {
                this.props.editItem(id);
                this.setState({activeId: id});
            } 
        }
         else {
             this.setState({detailsView: true, activeId: id});
             this.props.editItem(id);
         } 
    }

    hideDetails = () => {
        this.setState({detailsView: false, activeId: null});
    }

    itemsList = (items) => {
        const length = items && items.length ? items.length : 0;
        
       return ( 
       <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={125}
            visibleSlides={length > 5 ? 5 : length+1}
            totalSlides={length+1}>
                <Slider>
         {items.map((item, i) => {
            const {activeId} = this.state;
            const activeClass = activeId !== null && item.lineItemId == activeId ? '--active-item' : '';
                return (
                    <Slide index={i} key={`item-${i}`} className={`inventory-list_item ${activeClass}`}>
                        <div>
                            <div className={`category-icon --${item.category}`}></div>
                        </div>
                        <div>
                            <p className="item-info-line-1">{item.name ? item.name : ""}</p>
                        </div>
                        <OverlayTrigger 
                        show={this.state.showOverlay} 
                        placement="top"
                        overlay={
                        <Popover id={`popover-positioned-${i}`}>
                            <Popover.Content>
                            <span><strong>Have photos?</strong> Add them here</span>
                            </Popover.Content>
                      </Popover>} >
                        <div>
                       
                        <OverlayTrigger
                            key={`tooltip-${i}`}
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-${i}`}>
                                    Add details
                                </Tooltip>
                            }
                        >
                            <a href="#" className="edit-item --edit" onClick={()=>this.viewDetails(item.lineItemId)} value={item.lineItemId}></a>
                        </OverlayTrigger>
                        <OverlayTrigger
                            key={`tooltip-${i}`}
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-${i}`}>
                                    Add images
                                </Tooltip>
                            }
                        >
                            <a href="#" className="edit-item --upload" onClick={()=>this.viewDetails(item.lineItemId)} value={item.lineItemId}></a>
                        </OverlayTrigger>
                        </div>
                        </OverlayTrigger>

                        <OverlayTrigger
                            key={`tooltip-${i}`}
                            placement="right"
                            overlay={
                                <Tooltip id={`tooltip-${i}`}>
                                    Delete
                                </Tooltip>
                            }
                        >
                        <a href="#" className="delete-item" onClick={()=>this.props.deleteItem(item.lineItemId)} value={item.lineItemId}></a>
                        </OverlayTrigger>
                    </Slide>
                );
            })

        }
            <Slide index={length + 1} className="inventory-list_item --add-item">
                <div className="add-icon"></div>
                <a href="#" onClick={this.handleAddItem} className="add-item-btn">Add Item</a>
            </Slide>
            </Slider>
            <ButtonBack className="carousel-back-btn"></ButtonBack>
            <ButtonNext className="carousel-next-btn"></ButtonNext>
        </CarouselProvider>
       )
    }

    handleAddItem = () => {
        this.props.editItem(null);
        this.setState({
            detailsView: false,
            activeId: null,
            showAddItemModal: true
        });
    }

    render() {
        const { items, selectedFromList } = this.props;
        const disabled = items.length > 0 ? false : true;
        const headerMessage = selectedFromList ? "Good news! We’re interested." : "We're having trouble finding an exact match, but let's talk about it.";
        const infoMessage = selectedFromList ? "Bring it in to a store for an appraisal. We’ll get you paid on the same day. Trade it in and we’ll give you an insane deal on a new upgrade" : "If it's something we're interested in, the process is simple: bring it in to a store for an appraisal. We’ll get you paid on the same day. Trade it in and we’ll give you an insane deal on a new upgrade.";
        return ( 
            <div className="main-container">
                <a href="#" onClick={this.props.handleClose} className="exit-btn"></a>
                <div className="inventory-list-container"> 
                
                    {this.itemsList(items)}
                    {this.state.detailsView ? 
                    <SubmitDetails {...this.props} hideDetails={this.hideDetails}/> : null}
                    <h2>{headerMessage}</h2>
                    <h2>What’s Next?</h2>
                    <p>{infoMessage}</p>
                    <div className="button-container row">
                        <div className="col-sm-6 col-xs-12">
                            <label htmlFor="">Skip the lines, give us a time</label>
                            <NextButton name="schedule-btn" text="I'll be there at..." disabled={disabled} updateStep={this.props.updateStep} option="OPTION-A" submitItem={false} stepNum={5} />
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <label htmlFor="estimate-btn">Want to talk about it?</label>
                            <NextButton name="estimate-btn" text="Yes I do" disabled={disabled} updateStep={this.props.updateStep} option="OPTION-B" submitItem={false} stepNum={5}/>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.showAddItemModal} onHide={this.handleCloseModal} id="add-item-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Add Another Item</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AddItem {...this.props} handleCloseModal={this.handleCloseModal}/>
                    </Modal.Body>
                </Modal>
            </div>

        );
    }
}

export default InventoryList;